<!-- <mat-toolbar class="header display-flex justify-content-center">
  <span>Stock Planning</span>
</mat-toolbar> -->

<div class="fs-section-block fs-margin-0 header-background">
  <div>
    <span class="mat-headline">Stock Planning</span>
    <p class="fs-hint-text">Supplier's projected shipment & feedback    </p>
  </div>
</div>
<ejs-grid class="full-width-table" #grid *ngIf="data" class="full-width-table" [dataSource]='data'
  [allowPaging]='true' [allowFiltering]='true' [allowSorting]='true' [filterSettings]='filterOption'
  [allowSelection]='true' [enableHover]='false' [enableHeaderFocus]='true' [allowExcelExport]='true'
  [allowPdfExport]='true' [pageSettings]="pageSettings" [showColumnChooser]='true' height='600' allowTextWrap='true' [textWrapSettings]='wrapSettings'
  [loadingIndicator]="loadingIndicator" (rowSelected)="onRowSelected($event)" [toolbar]='toolbar' (toolbarClick)='toolbarClick($event)'
  (actionBegin)='actionBegin($event)'>
  <e-columns>
    <ng-container *ngFor="let header of dynamic_headers">
      <div *ngIf="header.image;then imageTemplate else default"></div>
      <ng-template #imageTemplate>
        <e-column [field]="header.field" [headerText]="header.headerText" [allowFiltering]="false"
                  [width]="header.width">
          <ng-template #template let-data>
            <div *ngIf="data[header.field]" class="info-icon cursor-pointer">
              <div (mouseenter)="data.showImage = true" (mouseleave)="data.showImage = false">
                <img style="float: left" src="{{data[header.field] | imageUrl}}" alt="Image" height="30" width="30" />
                <div class="{{data.showImage ? 'speech-bubble' : 'display-hide'}} left">
                  <img src="{{data[header.field] | imageUrl}}" alt="Image" />
                </div>
              </div>
            </div>
            <div *ngIf="!data[header.field]">
              <mat-icon>hide_image</mat-icon>
            </div>
          </ng-template>
        </e-column>
      </ng-template>
      <ng-template #default>
      <e-column *ngIf="!header.state && header.allowFiltering" [field]="header.field" [headerText]="header.headerText"
        [allowFiltering]="header.allowFiltering" [filter]="header.filter" [width]="header.width">
      </e-column>
      <e-column *ngIf="!header.state && !header.allowFiltering" [field]="header.field" [headerText]="header.headerText"
        [allowFiltering]="header.allowFiltering" [width]="header.width">
      </e-column>

      <e-column *ngIf="header.state && header.allowFiltering" [field]="header.field" [headerText]="header.headerText"
        [allowFiltering]="header.allowFiltering" [filter]="header.filter" [width]="header.width">
        <ng-template #template let-data>
          <div class="status {{data[header.field]}}">
            {{ data[header.field] }}
          </div>
        </ng-template>
      </e-column>
      </ng-template>
    </ng-container>
  </e-columns>
</ejs-grid>


<!-- <app-syncfusion-grid [dynamic_headers]="dynamic_headers" [data]="data" [reportName]="'stock_planning'"></app-syncfusion-grid> -->
