import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MenuService } from "src/app/services/menu.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { CommonUtilityService } from "../../services/common-utility-service";
import { Router } from "@angular/router";
import { EmitterService } from "../../services/emitter.service";
import { AuthenticationService } from "../../services/authentication.service";
import { MatSidenav } from "@angular/material/sidenav";
import { UserService } from "../../services/user.service";
import { ChangeDetectorRef, OnDestroy } from "@angular/core";
import { GetLogoService } from "src/app/services/get-logo.service";
import { AppSettingService } from "src/app/services/app-setting.service";

@Component({
  selector: "app-my-nav-template-version-v1",
  templateUrl: "./my-nav-template-version-v1.component.html",
  styleUrls: ["./my-nav-template-version-v1.component.scss"],
})
export class MyNavTemplateVersionV1Component {
  getMenuFromRole: any;
  @ViewChild("drawer") public drawer: MatSidenav;
  @ViewChild("drawerInnerContainer", { static: false })
  drawerInnerContainer: ElementRef;
  panelOpenState: boolean[] = [];
  menu: any = null;
  currentUser: any;
  isLoggedIn: boolean;
  displayMenu: any;
  mobileView: boolean = false;
  year: any = new Date().getFullYear();
  displayFlag: boolean;
  sessionTimeoutId: any;
  logo: any;
  isToggled = false;
  isErrorInMenuService: boolean = null;
  role: string = null;
  constructor(
    private getLogoService: GetLogoService,
    private breakpointObserver: BreakpointObserver,
    private utilService: CommonUtilityService,
    private el: ElementRef,
    private router: Router,
    private emitter: EmitterService,
    private authenticationService: AuthenticationService,
    private UserService: UserService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private menuFile: MenuService,
    private settingsService: AppSettingService
  ) {
    this.logo = getLogoService.clientLogoEndPointURL;
    this.emitter.isLoggedInCheck.subscribe((data) =>
      data ? this.checkAuthenticated() : ""
    );
    this.checkAuthenticated();
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  logout() {
    delete this.menu;
    clearTimeout(this.sessionTimeoutId);
    this.authenticationService.logout();
    this.emitter.isLoggedInCheck.next(false);
    this.checkAuthenticated();
    setTimeout(() => {
      this.router.navigate(["/login"]);
      location.reload(); 
    }, 200);
  }

  sessionTimeout() {
    if (this.isLoggedIn) {
      const twentyFiveMinutes = 25 * 60 * 1000;
      if (this.sessionTimeoutId) {
        clearTimeout(this.sessionTimeoutId);
      }
      this.sessionTimeoutId = setTimeout(() => {
        this.logout();
      }, twentyFiveMinutes);
    }
  }
  onImageError() {
    this.logo = "assets/img/logo.png";
  }
  getName() {
    let name = this.authenticationService.getUserName();
    if (!name) {
      return "";
    }
    let names = name?.split(" ");
    return names
      .map((value) => {
        return value.charAt(0);
      })
      .slice(0, 2)
      .join("")
      .toUpperCase();
  }
  checkAuthenticated() {
    this.isLoggedIn = this.authenticationService.isLoggedIn;
    if (this.authenticationService.isLoggedIn) {
      let getRole = setInterval(() => {
        if (this.authenticationService.getRole()) {
          clearInterval(getRole);
          this.isLoggedIn ? this.loadMenu() : "";
          this.role = this.authenticationService.getRole();
        }
      }, 100);
    } else {
      delete this.menu;
      this.role = null;
    }
  }
  
  loadMenu() {
    if (this.menu) {
      return;
    }
    this.isLoggedIn &&
      this.menuFile.getMenuFile(this.authenticationService.getRole()).subscribe(
        (res) => {
          this.getMenuFromRole = JSON.parse(res);
          this.menu = this.getMenuFromRole;
        },
        (error) => {
          this.isErrorInMenuService = true;
        }
      );
  }
  displayCopyrighrt(length) {
    if (this.menu.length - 1 == length) {
      this.displayFlag = true;
    }
  }

  @HostListener("window:mousemove", ["$event"])
  @HostListener("window:click", ["$event"])
  @HostListener("window:keydown", ["$event"])
  onUserInteraction(event: Event) {
    this.sessionTimeout();
  }

  togFunc(parentIndex) {
    if (parentIndex === 0) {
      return true;
    }
    return false;
  }
  navigateToChatbot() {
    // this.router.navigate(['']);
    this.router.navigate(['/ask-hawk'], { queryParams: { summarise: false } });
  }
  navigate(cat) {
    this.isHandset$.subscribe((data) => {
      this.mobileView = data;
    });
    this.mobileView ? this.drawer.toggle() : "";
    if ((cat && cat.route === "grid") || cat.route === "bufferHistory") {
      this.router.navigate([cat.route], {
        queryParams: { reportName: cat.params },
      });
    } else {
      this.router.navigate([cat.route, { data: cat.data ? cat.data : null }]);
    }
  }
  get isRemote(): boolean {
    return this.settingsService.isRemoteGridMode();
  }
}
