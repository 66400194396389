import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import {
  DataStateChangeEventArgs,
  FilterSearchBeginEventArgs,
  FilterSettingsModel,
  GridComponent,
  GroupSettingsModel,
  TextWrapSettingsModel,
} from "@syncfusion/ej2-angular-grids";
import { EmitType } from "@syncfusion/ej2-base";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { AppSettingService } from "src/app/services/app-setting.service";

@Component({
  selector: "app-historical-reports-molecules",
  templateUrl: "./historical-reports-molecules.component.html",
  styleUrls: ["./historical-reports-molecules.component.scss"],
})
export class HistoricalReportsMoleculesComponent {
  @Input() dynamic_headers;
  @Input() gridData;
  @Input() reportName;
  @ViewChild("grid", { static: false })
  public grid: GridComponent;
  @Input() isGrouping: boolean;
  @Output() navigateGrid = new EventEmitter<DataStateChangeEventArgs>();
  public groupOptions?: GroupSettingsModel;

  public filterOption: FilterSettingsModel = { type: "Excel" };
  pageSettings: { pageSizes: number[]; pageSize: number; locale: string };
  toolbar: string[];
  loadingIndicator: { indicatorType: string };
  count: number;
  public datePipe = new DatePipe("en-US");
  targetElement: any;
  container: any;
  public wrapSettings: TextWrapSettingsModel = { wrapMode: 'Both' };
  constructor(private settingService: AppSettingService){}
  ngOnInit() {
    if (this.isGrouping) {
      this.groupOptions = { showDropArea: false, columns: ["buffer"] };
    }
    let settings = this.settingService.getAppSetting('grid.pageSizes',[50,100]);
    this.pageSettings = {
      pageSizes:  settings,
      pageSize: settings[0],
      locale: "en-US"
    };
    this.toolbar = ["ExcelExport", "CsvExport", "ColumnChooser"];
    this.loadingIndicator = { indicatorType: "Shimmer" };
  }
  actionBegin(args: FilterSearchBeginEventArgs) {
    if (
      args.requestType === "filterchoicerequest" ||
      args.requestType === "filtersearchbegin"
    ) {
      args.filterChoiceCount = this.count;
    }
    if (args.requestType === "filterbeforeopen") {
      setTimeout(() => {
        const filterDialog = (args as any).filterModel?.dlgDiv;
        if (filterDialog) {
          const filterElement = filterDialog.querySelector('.e-autocomplete');
          if (filterElement && filterElement.ej2_instances) {
            filterElement.ej2_instances[0].actionBegin = (args)=>{
              args.cancel = true;
            }
          };
        }
      }, 0);
    }
  }
  dataStateChange(state: DataStateChangeEventArgs): void {
    this.navigateGrid.emit(state);
  }
  toolbarClick(args: ClickEventArgs): void {
    switch (args.item.text) {
      case "Excel Export":
        this.grid.excelExport(this.getExcelExportProperties());
        break;
      case "CSV Export":
        this.grid.csvExport(this.getCSVExportProperties());
        break;
    }
  }
  private getExcelExportProperties(): any {
    let currentDateTime = this.datePipe.transform(
      new Date(),
      "MM_dd_yy_hh:mm:ss"
    );
    return {
      fileName: this.reportName + "_" + currentDateTime + ".xlsx",
    };
  }
  private getCSVExportProperties(): any {
    let currentDateTime = this.datePipe.transform(
      new Date(),
      "MM_dd_yy_hh:mm:ss"
    );
    return {
      fileName: this.reportName + "_" + currentDateTime + ".csv",
    };
  }

  public initilaizeTarget: EmitType<object> = () => {
    this.targetElement = this.container.nativeElement.parentElement;
  };
}
