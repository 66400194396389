import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { SyncfusionService } from 'src/app/services/syncfusion.service';
import { grandTotalsRow, PivotViewComponent, ToolbarItems, ToolbarService } from '@syncfusion/ej2-angular-pivotview';
import { select } from '@syncfusion/ej2-base';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-pivot-table-molecule',
  providers: [ToolbarService],
  templateUrl: './pivot-table-molecule.component.html',
  styleUrls: ['./pivot-table-molecule.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PivotTableMoleculeComponent {
  @Input() rowCollection: any;
  @Input() rowField: any;
  @Input() columnCollection: any;
  @Input() columnField: any;
  @Input() rowFilters: any;
  @Input() pivotId: string;
  @ViewChild('pivotview', { static: false }) pivotObj: PivotViewComponent | undefined;
  public dataSourceSettings: any;
  public toolbarOptions: ToolbarItems[] = ['Export'];
  gridData: any = [];
  isLoading: boolean = false;

  constructor(private syncfusionService: SyncfusionService, private snack: MatSnackBar,) { }
  ngOnInit(): void {
    this.getPivotData();
  }
  showSnackbar(message: string) {
    this.snack.open(message, 'close', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
  getPivotData() {
    this.isLoading = true;
    const payload = {
      rowCollection: this.rowCollection || 'material',
      rowField: this.rowField || 'brand',
      columnCollection: this.columnCollection || 'stock',
      columnField: this.columnField || 'location',
      rowFilters: this.rowFilters|| [
        {
          "filterAttribute": "lifecycle_status",
          "filterValues": ["Active", "New"]
        }
      ]
    };

    this.syncfusionService?.getInventoryPivotData(payload).subscribe((response:any)=>{
      this.gridData = response;
      // this.gridData = [
      //   {
      //     "row": "Haute Sauce",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Metronaut",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Wallopedia",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "BLNCE",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "SOHI",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "SOHI",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "QISSA",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Evolve Back",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "0",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "0",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "PinaColoda",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Fighter X SOHI",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Metronaut",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Fighter X CS",
      //     "column": "Myntra Flex",
      //     "inventory": 89
      //   },
      //   {
      //     "row": "PINACOLADA",
      //     "column": "Flipkart Flex",
      //     "inventory": 44
      //   },
      //   {
      //     "row": "Vybe",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "BLNCE",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Pinacolada",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "SOHI",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Water Theory",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Evolve Back",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Sohi",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Instafab plus",
      //     "column": "Flipkart Flex",
      //     "inventory": 23
      //   },
      //   {
      //     "row": "Veda Cultr",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "CAMPUS SUTRA",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "PinaColoda",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "French Accent",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "PinaColoda",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Campus Sutra",
      //     "column": "Flipkart Flex",
      //     "inventory": 44516
      //   },
      //   {
      //     "row": "Vedacultr",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "PINACOLADA",
      //     "column": "Myntra Flex",
      //     "inventory": 112
      //   },
      //   {
      //     "row": "Evolve Back",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Bonhomie",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "CAMPUS SUTRA",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Via Tokyo",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Instafab Plus",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Via Tokyo",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "French Accent",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Sohi",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Sohi",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Campus Sutra",
      //     "column": "Myntra Flex",
      //     "inventory": 54288
      //   },
      //   {
      //     "row": "Via Tokyo",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "BLNCE",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Campus Sutra",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Instafab plus",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Wallopedia",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Instafab",
      //     "column": "Myntra Flex",
      //     "inventory": 3
      //   },
      //   {
      //     "row": "QISSA",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Fighter X CS",
      //     "column": "Flipkart Flex",
      //     "inventory": 13
      //   },
      //   {
      //     "row": "Bonhomie",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "CS Life",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Instafab",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Xiaomi",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "VIA TOKYO",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Veda Cultr",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Water Theory",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "CS Life",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Vedacultr",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Haute Sauce",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Pinacolada",
      //     "column": "Flipkart Flex",
      //     "inventory": 11
      //   },
      //   {
      //     "row": "Instafab",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Xiaomi",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Metronaut",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "CS Life",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Fighter X CS",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Vybe",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Instafab Plus",
      //     "column": "Flipkart Flex",
      //     "inventory": 4470
      //   },
      //   {
      //     "row": "French Accent",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Vedacultr",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Fighter X SOHI",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Instafab plus",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "VIA TOKYO",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Veda Cultr",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Instafab Plus",
      //     "column": "Myntra Flex",
      //     "inventory": 9042
      //   },
      //   {
      //     "row": "Wallopedia",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "0",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Xiaomi",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "CAMPUS SUTRA",
      //     "column": "Flipkart Flex",
      //     "inventory": 426
      //   },
      //   {
      //     "row": "QISSA",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Pinacolada",
      //     "column": "Myntra Flex",
      //     "inventory": 99
      //   },
      //   {
      //     "row": "Haute Sauce",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "PINACOLADA",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Vybe",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Bonhomie",
      //     "column": "Myntra Flex",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Water Theory",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "Fighter X SOHI",
      //     "column": "WMS",
      //     "inventory": 0
      //   },
      //   {
      //     "row": "VIA TOKYO",
      //     "column": "Flipkart Flex",
      //     "inventory": 0
      //   }
      // ];
      this.gridData = this.gridData?.map((item: any) => {
        return {
          ...item,
          row: item?.row?.toUpperCase(), // Transform 'row' to uppercase
        };
      });
      this.dataSourceSettings = {
        dataSource: this.gridData,
        rows: [{ name: 'row', caption: 'Brand' }],
        columns: [{ name: 'column' }],
        values: [{ name: 'inventory' }],
        toolbar: ['Excel', 'Csv'],
        actionBegin: (args) => {
          if (args.requestType === 'toolbarItemClick') {
            if (args.item.id === 'PivotGrid_Excel') {
              this.pivotObj.excelExport();
            }
            if (args.item.id === 'PivotGrid_Csv') {
              this.pivotObj.csvExport();
            }
          }
        }
      }
      this.pivotObj?.refresh();
      this.isLoading = false;
    },(error)=>{
      this.showSnackbar("Failed to aggregate inventory data, retry after sometime");
      this.isLoading = false;
    });
  }
  dataBound(args: any) {
    if(this.pivotObj){
      let pivotID = this.pivotId;
      let exportMenu = select('#' + pivotID + 'export_menu', this.pivotObj.element).ej2_instances[0];
      exportMenu.beforeItemRender = function (args: any) {
        if (args.element.id == pivotID + "pdf") {
          args.element.style.display = "none";
        }
      }
    }
  }
}
