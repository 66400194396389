<div class="main-container">
    <app-header-content-help-text Heading="Variant Mix Calculator" helpText="Align Manufacturing with Demand"></app-header-content-help-text>
    <p class="help-text">Enter style code and production units to calculate SKU manufacturing quantities</p>
    <div class="input-container">
        <div class="fields-cont" [formGroup]="productForm">
            <mat-form-field class="product-field">
                <mat-label>Style Code</mat-label>
                <input matInput formControlName="product" [matAutocomplete]="auto" placeholder="Enter product code" />
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onProductChange($event)"
                    [displayWith]="displayProduct">
                    <mat-option *ngFor="let product of filteredProducts | async" [value]="product">
                        {{ product }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="quantity-fied">
                <input type="number" matInput formControlName="quantity" placeholder="Quantity">
            </mat-form-field>
            <button class="add-btn mat-button mat-primary mat-raised-button" [disabled]="productForm.invalid"
             (click)="addProduct()">Add</button>
        </div>
    </div>
    <div class="product-container">
        <div class="dropdown-cont" [formGroup]="productForm">
            <div *ngFor="let product of products.controls; let i = index" class="prefilter-condition-card-cont"
                [formGroup]="product">
                <mat-card class="prefilter-condition-card">
                    <div class="card-content">
                        <span class="headline">Style Code</span>
                        <span class="value">{{product.value.code ? product.value.code : '-'}}</span>
                    </div>
                    <div class="card-content">
                        <span class="headline">Quantity</span>
                        <span class="value">{{product.value.quantity ? product.value.quantity : '-'}}</span>
                    </div>
                    <button mat-icon-button color="warn" (click)="removeProduct(i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-card>
            </div>
        </div>
        <div class="actions" *ngIf="products.length > 0">
            <button class="add-btn mat-button mat-primary mat-raised-button"
             (click)="convertToSizeRatio()">Convert</button>
        </div>
    </div>
    <div *ngIf="dynamic_headers?.length>0">
        <app-syncfusion-grid [dynamic_headers]="dynamic_headers" [data]="gridData" *ngIf="tableDataFetched"></app-syncfusion-grid>
      </div>
</div>