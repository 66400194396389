import { Component, OnInit, Inject } from '@angular/core';
import { LocationsService } from '../../../../services/locations-service';
import { FormDataConstructorService } from '../../../../services/form-data-constructor.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-locations-form-organism',
  templateUrl: './locations-form-organism.component.html',
  styleUrls: ['./locations-form-organism.component.scss']
})
export class LocationsFormOrganismComponent implements OnInit {
  formData: any;
  addFlag: boolean = true;
  locationId: string | null = null;

  constructor(
    private locationsService: LocationsService,
    private formService: FormDataConstructorService,
    private snack: MatSnackBar,
    public dialogRef: MatDialogRef<LocationsFormOrganismComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { code?: string }
  ) {
    // Load form metadata
    this.locationsService.getLocationsForm().subscribe((res: any) => {
      let metaData = res.metaData;
      
      if (data && data.code) {
        this.addFlag = false;
        this.locationsService.getLocationByCode(data.code).subscribe((locationData: any) => {
          this.locationId = locationData.id;

          this.locationsService.getLocationAttributes(data.code).subscribe((attributeData: any) => {
            locationData.dynamicAttrs = attributeData.attributes;
            const processedAttrs = this.processAttrData(locationData);
            const processedMetaData = this.processMetaData(metaData, processedAttrs);
            this.formData = this.formService.formConstructor(processedMetaData);
          });
        });
      } else {
        this.addFlag = true;
        metaData = metaData.filter(each => each.identifier !== 'createdTime' && each.identifier !== 'modifiedTime');
        this.formData = this.formService.formConstructor(metaData);
      }
    });
  }

  ngOnInit(): void {}

  // Process dynamic attributes into form data
  processAttrData(data: any) {
    data.dynamicAttrs.forEach((each: any) => {
      const key = each.attributeName;
      const val = each.attributeValue;
      data[key] = val;
    });
    return data;
  }

  // Map metaData with formData
  processMetaData(metaData: any[], data: any) {
    const processedMetaData = [...metaData];
  
    // Map values from data to metaData
    processedMetaData.forEach((eachMetaData) => {
      const key = eachMetaData.identifier;
      if (data[key] !== undefined) {
        eachMetaData.value = data[key];
      }
      eachMetaData.isReadOnly = ["createdTime", "modifiedTime"].includes(key);
    });
  
    return processedMetaData;
  }
  
  // processMetaData(metaData: any[], data: any) {
  //   const processedMetaData = [];
  //   Object.keys(data).forEach((singleKey) => {
  //     metaData.forEach((eachMetaData) => {
  //       if (eachMetaData && singleKey === eachMetaData.identifier) {
  //         if (eachMetaData.dynamic) {
  //           data.dynamicAttrs.forEach((dynamicAttr: any) => {
  //             if (singleKey === dynamicAttr.attributeName) {
  //               eachMetaData['id'] = dynamicAttr.id;
  //             }
  //           });
  //         }
  //         eachMetaData.value = data[singleKey];
  //         if (singleKey === 'createdTime' || singleKey === 'modifiedTime') {
  //           eachMetaData.isReadOnly = true;
  //         } else {
  //           eachMetaData.isReadOnly = false;
  //         }
  //         processedMetaData.push(eachMetaData);
  //       }
  //     });
  //   });
  //   return processedMetaData;
  // }

  // Submit form
  submitForm(formValues: any) {
    this.locationsService.saveLocation(formValues, this.formData, this.addFlag, this.locationId)
      .subscribe(() => {
        const message = this.addFlag ? 'Added successfully' : 'Changes saved successfully';
        this.snack.open(message, 'close', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        this.dialogRef.close(true); // Close modal and pass data
      });
  }

  // Close modal without saving
  onCancel(): void {
    this.dialogRef.close(false);
  }
}
