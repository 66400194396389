import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { api } from '../properties/endpoints';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  constructor(private http: HttpClient) { }
  getAllLocations() {
    const url = `${api.host}/location/all`;
    return this.http.get(url);
  }

  getNextLocation(page) {
    const url = `${api.host}/location/all?${page}`;
    return this.http.get(url);
  }

  getLocationsForm() {
    // const api = '../../assets/mock/allMetaData.json';
    const url = `${api.host}/location/metadata`;
    return this.http.get(url);
  }

  getLocationByCode(code) {
    // const api = '../../assets/mock/getMaterialByCode.json';
    const url = `${api.host}/location/` + code;
    return this.http.get(url);
  }

  getLocationAttributes(code) {
    // const api = '../../assets/mock/getMaterialAttributes.json';
    const url = `${api.host}/location/` + code + '/attributes';
    return this.http.get(url);
  }

  // saveLocation(formValues, formData, addFlag, id): Observable<any[]> {
  //   const locationSaveApi = `${api.host}/location/save`;
  //   const locationAttributeSaveApi = `${api.host}/location/update/attributes`;
  //   let resultJson = {};
  //   let resultJsonAttributes = [];
  //   resultJson['id'] = id;
  //   formData.map((eachFormData) => {
  //     if (eachFormData.dynamic) {
  //       const attributeId = addFlag ? null : eachFormData.id;
  //       resultJsonAttributes.push({
  //         'id': attributeId,
  //         'code': formValues['code'].value,
  //         'attributeName': eachFormData.identifier,
  //         'attributeValue': formValues[eachFormData.identifier].value
  //       });
  //     } else {
  //       resultJson[eachFormData.identifier] = formValues[eachFormData.identifier].value;
  //       if (eachFormData.dataType == "Array") {
  //         resultJson[eachFormData.identifier] = formValues[eachFormData.identifier].value && formValues[eachFormData.identifier].value instanceof Array ? formValues[eachFormData.identifier].value : formValues[eachFormData.identifier].value.split(',');
  //       resultJson[eachFormData.identifier] = formValues[eachFormData.identifier].value;
  //       if (eachFormData.dataType == "Array") {
  //         resultJson[eachFormData.identifier] = formValues[eachFormData.identifier].value && formValues[eachFormData.identifier].value instanceof Array ? formValues[eachFormData.identifier].value : formValues[eachFormData.identifier].value.split(',');
  //       }
  //     }
  //   }
  // })
  //   return forkJoin([this.http.post<any>(locationSaveApi, resultJson), this.http.post<any>(locationAttributeSaveApi, resultJsonAttributes)]);
  // }


  saveLocation(formValues, formData, addFlag, id): Observable<any[]> {
    const locationSaveApi = `${api.host}/location/save`;
    const locationAttributeSaveApi = `${api.host}/location/update/attributes`;
    let resultJson = {};
    let resultJsonAttributes = [];
  
    resultJson['id'] = id;
  
    formData.forEach((eachFormData) => {
      if (eachFormData.dynamic) {
        const attributeId = addFlag ? null : eachFormData.id;
        const attributeValue = formValues[eachFormData.identifier]?.value;
  
        // Skip empty attributes
        if (attributeValue !== undefined && attributeValue !== null && attributeValue !== "") {
          resultJsonAttributes.push({
            'id': attributeId,
            'code': formValues['code']?.value,
            'attributeName': eachFormData.identifier,
            'attributeValue': attributeValue,
          });
        }
      } else {
        const value = formValues[eachFormData.identifier]?.value;
  
        // Skip optional fields with empty/default values
        if (eachFormData.optional && (!value || (Array.isArray(value) && value.length === 0))) {
          return;
        }
  
        if (eachFormData.dataType === "Array") {
          resultJson[eachFormData.identifier] =
            value && value instanceof Array ? value : value.split(',');
        } else {
          resultJson[eachFormData.identifier] = value;
        }
      }
    });
  
    console.log('Result JSON:', resultJson);
    console.log('Result Attributes JSON:', resultJsonAttributes);
  
    return forkJoin([
      this.http.post<any>(locationSaveApi, resultJson),
      this.http.post<any>(locationAttributeSaveApi, resultJsonAttributes),
    ]).pipe(
      catchError((error) => {
        console.error('API Error:', error);
        return throwError(error);
      })
    );
  }
  



  //sending without state

  // saveLocation(formValues, formData, addFlag, id): Observable<any[]> {
  //   const locationSaveApi = `${api.host}/location/save`;
  //   const locationAttributeSaveApi = `${api.host}/location/update/attributes`;
  
  //   const resultJson: any = { id: id || null }; // Ensure `id` is included
  //   const resultJsonAttributes: any[] = [];
  
  //   formData.forEach((eachFormData) => {
  //     if (eachFormData.identifier === 'state') {
  //       // Skip the 'state' field
  //       return;
  //     }
  
  //     if (eachFormData.dynamic) {
  //       // Handle dynamic fields
  //       const attributeId = addFlag ? null : eachFormData.id;
  //       resultJsonAttributes.push({
  //         id: attributeId,
  //         code: formValues['code']?.value || '',
  //         attributeName: eachFormData.identifier,
  //         attributeValue: formValues[eachFormData.identifier]?.value || '',
  //       });
  //     } else {
  //       // Handle non-dynamic fields
  //       if (eachFormData.dataType === "Array") {
  //         resultJson[eachFormData.identifier] = Array.isArray(formValues[eachFormData.identifier]?.value)
  //           ? formValues[eachFormData.identifier]?.value
  //           : formValues[eachFormData.identifier]?.value?.split(',') || [];
  //       } else {
  //         resultJson[eachFormData.identifier] = formValues[eachFormData.identifier]?.value || '';
  //       }
  //     }      
  //   });
  
  //   // Debugging logs
  //   console.log('Payload for location save:', resultJson);
  //   console.log('Payload for attributes save:', resultJsonAttributes);
  
  //   return forkJoin([
  //     this.http.post<any>(locationSaveApi, resultJson),
  //     this.http.post<any>(locationAttributeSaveApi, resultJsonAttributes),
  //   ]);
  // }


   //sending state as string

  // saveLocation(formValues, formData, addFlag, id): Observable<any[]> {
  //   const locationSaveApi = `${api.host}/location/save`;
  //   const locationAttributeSaveApi = `${api.host}/location/update/attributes`;
  //   let resultJson = {};
  //   let resultJsonAttributes = [];
  //   resultJson['id'] = id;
  
  //   formData.map((eachFormData) => {
  //     if (eachFormData.dynamic) {
  //       const attributeId = addFlag ? null : eachFormData.id;
  //       resultJsonAttributes.push({
  //         id: attributeId,
  //         code: formValues['code']?.value || '',
  //         attributeName: eachFormData.identifier,
  //         attributeValue: formValues[eachFormData.identifier]?.value || '',
  //       });
  //     } else {
  //       if (eachFormData.identifier === "state") {
  //         const stateValue = formValues[eachFormData.identifier]?.value;
  //         resultJson[eachFormData.identifier] = Array.isArray(stateValue) 
  //           ? stateValue.join(",") 
  //           : stateValue || "";
  //       } else if (eachFormData.dataType === "Array") {
  //         resultJson[eachFormData.identifier] = Array.isArray(formValues[eachFormData.identifier]?.value)
  //           ? formValues[eachFormData.identifier]?.value
  //           : formValues[eachFormData.identifier]?.value?.split(',') || [];
  //       } else {
  //         resultJson[eachFormData.identifier] = formValues[eachFormData.identifier]?.value || '';
  //       }
  //     }
  //   });
  
  //   return forkJoin([
  //     this.http.post<any>(locationSaveApi, resultJson),
  //     this.http.post<any>(locationAttributeSaveApi, resultJsonAttributes),
  //   ]);
  // }


  //sending state as empty array

  // saveLocation(formValues, formData, addFlag, id): Observable<any[]> {
  //   const locationSaveApi = `${api.host}/location/save`;
  //   const locationAttributeSaveApi = `${api.host}/location/update/attributes`;
  //   let resultJson = {};
  //   let resultJsonAttributes = [];
  //   resultJson['id'] = id;
  
  //   formData.map((eachFormData) => {
  //     if (eachFormData.dynamic) {
  //       const attributeId = addFlag ? null : eachFormData.id;
  //       resultJsonAttributes.push({
  //         id: attributeId,
  //         code: formValues['code']?.value || '',
  //         attributeName: eachFormData.identifier,
  //         attributeValue: formValues[eachFormData.identifier]?.value || '',
  //       });
  //     } else {
  //       if (eachFormData.identifier === "state") {
  //         // Handle `state` as an empty array if no value is provided
  //         const stateValue = formValues[eachFormData.identifier]?.value;
  //         resultJson[eachFormData.identifier] = stateValue && stateValue.length > 0
  //           ? (Array.isArray(stateValue) ? stateValue : [stateValue])
  //           : [];
  //       } else if (eachFormData.dataType === "Array") {
  //         resultJson[eachFormData.identifier] = Array.isArray(formValues[eachFormData.identifier]?.value)
  //           ? formValues[eachFormData.identifier]?.value
  //           : formValues[eachFormData.identifier]?.value?.split(',') || [];
  //       } else {
  //         resultJson[eachFormData.identifier] = formValues[eachFormData.identifier]?.value || '';
  //       }
  //     }
  //   });
  
  //   return forkJoin([
  //     this.http.post<any>(locationSaveApi, resultJson),
  //     this.http.post<any>(locationAttributeSaveApi, resultJsonAttributes),
  //   ]);
  // }
  
  
  
}
