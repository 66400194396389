import { Component } from '@angular/core';

@Component({
  selector: 'app-inventory-pivot-organism',
  templateUrl: './inventory-pivot-organism.component.html',
  styleUrls: ['./inventory-pivot-organism.component.scss']
})
export class InventoryPivotOrganismComponent {
  rowCollection: any = "material";
  rowField: any = "brand";
  columnCollection: any = "stock";
  columnField: any = "location";
  apparelRowFilters: any = [
    {
      "filterAttribute": "category_level3",
      "filterValues": ["Apparels"]
    }
  ];
  accessoryRowFilters:any = [
    {
      "filterAttribute": "category_level3",
      "filterValues": ["Accessories"]
    }
  ];

  ngOnInit(): void {
  }


}
