import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPrice',
})
export class FormatPricePipe implements PipeTransform {
  transform(value: number, locale: string = 'en-IN', currency: string = 'INR'): string {
    const formattedValue = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      maximumFractionDigits: 2,
    }).format(value);

    // Remove ".00" if the value is a whole number
    return value % 1 === 0 ? formattedValue.replace(/\.00$/, '') : formattedValue;
  }
}
