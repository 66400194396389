import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { api } from "../properties/endpoints";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FilterBayService {
  getFiltersFromParent = new Subject<any>();
  getMaterialMasterFilters=new Subject<any>();

  constructor(private http: HttpClient) {}

  getFilters() {
    const url = `${api.host}/reports/sales/visualize/filters`;
    return this.http.get(url);
  }
  getListingFilters(){
    const url = `${api.host}/reports/listing/visualize/filters`;
    return this.http.get(url);
  }
  getvisualization() {
    const url = `${api.host}/reports/sales/visualize`;
    return this.http.get(url);
  }
  getSalesBreakdown(payload) {
    const url = `${api.host}/reports/sales/visualize/pie`;
    return this.http.post(url, payload);
  }
  applyFilterOnVisualizer(payload) {
    const url = `${api.host}/reports/sales/visualize/apply/filter`;
    return this.http.post(url, payload);
  }
  applyFilterOnScoreCard(payload) {
    const url = `${api.host}/reports/sales/scorecard/apply/filter`;
    return this.http.post(url, payload);
  }
  getScoreCard() {
    const url = `${api.host}/reports/sales/scorecard`;
    return this.http.get(url);
  }

  setFilters(filter) {
    this.getFiltersFromParent.next(filter);
  }
  setFiltersForMaterialMaster(filter){
     this.getMaterialMasterFilters.next(filter)
  }
}
