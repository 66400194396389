<div [formGroup]="form" *ngIf='fieldData'>


  <div [ngSwitch]="fieldData.controlType">

    <mat-form-field *ngSwitchCase="'textbox'" appearance="outline">
      <mat-label>{{fieldData.label}}</mat-label>
      <input matInput placeholder="Input" [readonly]="fieldData.isReadOnly" [id]="fieldData.identifier"
        [formControlName]="fieldData.identifier" autocomplete="off">
      <mat-error class="error-message" *ngIf="!isValid">{{fieldData.label}} is required</mat-error>
    </mat-form-field>


    <ng-container *ngSwitchCase="'Array'">
      <div *ngIf="fieldData.identifier=='components'">
        <mat-label>{{fieldData.label}}</mat-label>
        <h1></h1>
        <div formArrayName="components">
          <div *ngFor="let group of returnArray().controls; let i = index">
            <div [formGroupName]="i">
              <mat-form-field appearance="outline" style="width:200px !important" class="col-md-6">
                <mat-label>Code</mat-label>
                <input class="col-xs-3" matInput placeholder="Input" [readonly]="fieldData.isReadOnly"
                  [id]="fieldData.identifier" [formControlName]="'code'" autocomplete="off">
                <mat-error class="error-message" *ngIf="!isValid">code is required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" style="width:200px !important" class="col-md-6">
                <mat-label>Quantity</mat-label>
                <input class="col-xs-3" matInput placeholder="Input" [readonly]="fieldData.isReadOnly"
                  [id]="fieldData.identifier" [formControlName]="'qty'" autocomplete="off">
                <mat-error class="error-message" *ngIf="!isValid">quantity is required</mat-error>
              </mat-form-field>
              <button type="button" class="btn btn-danger btn-sm" (click)="removeObjects(i)">
                Remove
              </button>
            </div>
          </div>
        </div>
        <button type="button" class="btn btn-success" (click)="addFormGroup()">Add Component</button>
      </div>
      <!-- {{this.form.value | json}} -->
      <h1></h1>
    </ng-container>

    <mat-form-field  class="space" *ngSwitchCase="'passwd'" appearance="outline">
      <mat-label>{{fieldData.label}}</mat-label>
      <input matInput placeholder="Input" [readonly]="fieldData.isReadOnly" [id]="fieldData.identifier"
        [formControlName]="fieldData.identifier" type="password" autocomplete="off">
      <mat-error class="error-message" *ngIf="fieldData.identifier==='currentpassword'">{{fieldData.label}} is required</mat-error>
      <!-- <mat-error class="error-message" *ngIf="validate">Password must be at least 8 characters long.</mat-error> -->
      <mat-error class="error-message" *ngIf="form.get(fieldData.identifier)?.hasError('minlength')">
        Password length min 8 characters.
      </mat-error>
      <mat-error class="error-message" *ngIf="form.get(fieldData.identifier)?.hasError('pattern')">
        Atleast one spl char (!, @, #, $, %, ^, &, *, (, ), ,, ., ?, :, {{'{'}}
        <p>Atleast one Upper case and one Lower case alphabet.</p>
      </mat-error>
    </mat-form-field>


    <mat-form-field *ngSwitchCase="'dropdown'" appearance="outline">
      <mat-label>{{fieldData.label}}</mat-label>
      <mat-select [formControlName]="fieldData.identifier">
        <mat-option *ngFor="let opt of fieldData.options" [value]="opt">
          {{opt}}
        </mat-option>
      </mat-select>
      <mat-error class="error-message" *ngIf="!isValid">{{fieldData.label}} is required</mat-error>
    </mat-form-field>

    <div class="radio-wrapper" *ngSwitchCase="'radio'">
      <label class="radio-label">{{fieldData.label}}</label>
      <mat-radio-group [formControlName]="fieldData.identifier" class="radio-group">
        <mat-radio-button class="radio-button" *ngFor="let opt of fieldData.options"
          [checked]="fieldData.value !== null && fieldData.value !== undefined && fieldData.value.toString() == opt.key ? true : false"
          [value]="opt.key">
          {{opt.value}}
        </mat-radio-button>
      </mat-radio-group>
    </div>

  <mat-form-field *ngSwitchCase="'datepicker'">
    <mat-label>{{fieldData.label}}</mat-label>
    <input matInput  [matDatepicker]="picker1" [min]="!fieldData.value?currentDate:''" [disabled]="fieldData.isReadOnly" [id]="fieldData.identifier"
    [formControlName]="fieldData.identifier" (dateChange)="onDateChange($event,fieldData)">
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
    <mat-error class="error-message" *ngIf="!isValid">{{fieldData.label}} is required</mat-error>
  </mat-form-field>
  </div>

</div>
