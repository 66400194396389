import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { InquiriesManagementService } from 'src/app/services/inquiries-management.service';
import * as moment from 'moment';
import { AppSettingService } from 'src/app/services/app-setting.service';
import { PinsService } from 'src/app/services/pins.service';

@Component({
  selector: 'app-inquiries-molecule',
  templateUrl: './inquiries-molecule.component.html',
  styleUrls: ['./inquiries-molecule.component.scss'],
  animations: [
    trigger('slide', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms ease',
          style({ opacity: 1 })
        ),
        query("@*", [animateChild()], { optional: true })
      ]),
      transition(':leave', [
        query("@*", [animateChild()], { optional: false }),
      ]),
    ]),

    trigger('childAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('800ms cubic-bezier(0.2, 1, 0.3, 1)',
          style({ transform: 'translateX(0%)' })
        )
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('300ms ease',
          style({
            transform: 'translateX(100%)',
            boxShadow: '0px 0 00px 0px rgba(87,73,86,0.0)'
          }
          )
        )
      ])
    ])

  ]
})
export class InquiriesMoleculeComponent {
  @Input() formData: any;
  @Output() inquiryUpdated = new EventEmitter<string>();
  @Output() refreshInquiryList = new EventEmitter<string>();
  @ViewChild('overlayTemplate', { static: true }) overlayTemplate!: TemplateRef<any>;
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;
  overlayRef!: OverlayRef;
  commentForm: FormGroup;
  private _userName: string | null = null;
  commentsList: any
  formGroup: FormGroup;
  customerDetails = {
    id: '',
    code: '',
    name: '',
    contact: '',
    address: ''
  };
  customersList: any = [];
  selectedCustomer: any;
  createdInquiryDetails: any;
  customerSalesData: any = [];
  customerSalesHeaders: any = [];
  materialLookupData: any = [];
  materialLookupHeaders: any = [];
  inquiryItemsHeaders: any = [];
  inquiryItemsData: any = [];
  settings: any;
  inquiryOrdersColumns: any
  inquiryMaterialColumns: any;
  inquiryItemsColumns: any;
  isAddItemsButtonEnabled = false;
  enableSave: boolean = false;
  inquiryId: any = null;

  constructor(private overlay: Overlay, private settingService: AppSettingService,
    private authenticationService: AuthenticationService, private inquiryService: InquiriesManagementService, private pinsService: PinsService,
    private viewContainerRef: ViewContainerRef, private fb: FormBuilder, private snack: MatSnackBar, private dialog: MatDialog,) { }

  ngOnInit() {
    this.initForm();
    const settings = this.settingService.getSettings();
    if (settings) {
      this.inquiryItemsColumns = settings?.inquiry_item_columns;
      this.inquiryMaterialColumns = settings?.inquiry_material_columns;
      this.inquiryOrdersColumns = settings?.inquiry_orders_columns;
    }
    if (this.formData) {
      this.inquiryId = this.formData.id;
      this.formGroup.get('lookupCustomer')?.disable();
      this.getInquiryDetails(this.inquiryId);
      this.fetchComments(this.inquiryId);
    }
  }

  private initForm() {
    this.formGroup = this.fb.group({
      lookupCustomer: [''],
      lookupQualityDesign: ['']
    });
    this.commentForm = this.fb.group({
      comment: ['', Validators.required]
    });
  }
  onLookup(field: string) {
    if (field === 'lookupCustomer') {
      const custCode: any = this.formGroup?.value?.lookupCustomer;
      this.customerLookup(custCode);
    } else if (field === 'lookupQualityDesign') {
      const materialCode: any = this.formGroup?.value?.lookupQualityDesign;
      if (this.createdInquiryDetails || this.formData !== null) {
        this.materialLookup(materialCode);
      } else {
        this.showSnackbar("Please Lookup for customer before proceeding");
        this.clearInputField(field);
      }
    }
  }
  customerLookup(code: any) {
    this.inquiryService.getCustomerLookup(code).subscribe((res: any) => {
      this.customersList = res;
      if (res.length) {
        if (this.customersList.length > 1) {
          this.selectedCustomer = '';
          this.dialog.open(this.dialogTemplate, {
            width: '300px',
          });
        } else {
          this.processCustomer(this.customersList[0]);
        }
      } else {
        this.showSnackbar("Couldn't find the customer. Please enter a valid id or name");
        this.formGroup.get('lookupCustomer')?.setValue('');
      }

    }, (error) => {
      console.log("Error while lookingup for customer: ", error)
    })
  }
  confirmCustomerSelection(dialogRef: any) {
    if (this.selectedCustomer) {
      this.processCustomer(this.selectedCustomer);
      dialogRef.close();
    }
  }
  processCustomer(customer: any) {
    this.createInquiry(customer);
    this.customerDetails = {
      id: customer.id || '',
      code: customer.code || '',
      name: customer.name || '',
      contact: customer.contact || '',
      address: customer.address || ''
    };
    this.formGroup.get('lookupCustomer')?.setValue(this.customerDetails.code);
  }
  createInquiry(customer: any) {
    const code = customer.name + '-' + moment().format('DD-MM-YYYY');
    const customerId = customer.id;
    const payload = { code: code };
    this.inquiryService.createInquiry(payload).subscribe((res: any) => {
      this.createdInquiryDetails = res;
      this.inquiryId = this.createdInquiryDetails.id;
      this.showSnackbar("Quote created successfully");
      if (this.inquiryId) {
        this.inquiryService.selectCustomerForInquiry(this.inquiryId, customerId).subscribe((response: any) => {
          this.formGroup.get('lookupCustomer')?.disable();
          this.refreshInquiryList.emit(this.createdInquiryDetails);
        },(error) => {
          console.log("Error while creating quote: ", error);
        })
      }
    }, (error) => {
      console.log("Error while creating quote: ", error);
    })
  }
  materialLookup(code: any) {
    this.inquiryService.getMaterialLookup(code).subscribe((res: any) => {
      if (res.length) {
        this.materialLookupData = this.flattenData(res);
        this.materialLookupHeaders = Object.keys(this.inquiryMaterialColumns);
      } else {
        this.showSnackbar("Please enter a valid id or name");
        this.formGroup.get('lookupQualityDesign')?.setValue('');
      }
    }, (error) => {
      console.log("Error while lookingup for design: ", error);
    });
  }
  checkInputs() {
    this.isAddItemsButtonEnabled = this.materialLookupData.some((item: any) =>
      +item.orderQty > 0 || +item.price > 0
    );
  }
  onDateChange(event: any, element: any, column: string): void {
    const localDate = moment(event.value).startOf('day');
    const formattedDate = localDate.format('YYYY-MM-DD');
    element[column] = formattedDate;
    this.checkIfSaveEnabled();

  }
  checkIfSaveEnabled() {
    this.enableSave = this.inquiryItemsData.some((item: any) => {
      return item.customDeliveryDate != null && item.customDeliveryDate !== item.computedDeliveryDate;
    });
  }

  onSave(element: any) {
    const payload = {
      inquiryItemId: element.id,
      price: element.price,
      customDeliveryDate: element.customDeliveryDate,
      orderQty: element.orderQty
    };
    this.inquiryService.updateInquiryItem(payload).subscribe(response => {
      this.showSnackbar("Item updated successfully");
      this.enableSave = false;
      this.getInquiryDetails(this.inquiryId);
    });
  }
  flattenData(data: any[]): any[] {
    return data.map(item => ({
      ...item,
      ...item.dynamicAttributes
    }));
  }
  addItemsToInquiry() {
    const selectedItems = this.materialLookupData.filter((item: any) => +item.orderQty > 0);
    const payload = selectedItems.map((item: any) => ({
      inquiryId: this.inquiryId,
      materialCode: item.code,
      dynamicAttributes: {
        blend: item.dynamicAttributes?.blend || "",
        design: item.dynamicAttributes?.design || "",
        quality: item.dynamicAttributes?.quality || "",
        shade: item.dynamicAttributes?.shade || ""
      },
      orderQty: +item.orderQty,
      computedDeliveryDate: "",
      customDeliveryDate: "",
      price: item.price || ""
    }));
    this.inquiryService.addItemstoInquiry(payload, this.inquiryId).subscribe((res: any) => {
      this.materialLookupData.forEach((item: any) => {
        item.orderQty = '';
        item.price = '';
      });
      this.isAddItemsButtonEnabled = false;
      this.getInquiryDetails(this.inquiryId);
      this.showSnackbar("Added to items successfully");
    })
  }
  getInquiryDetails(id: any) {
    this.inquiryService.getInquiryDetails(id).subscribe((response: any) => {
      if (response?.customer) {
        this.customerDetails = {
          id: response.customer.id || '',
          code: response.customer.code || '',
          name: response.customer.name || '',
          contact: response.customer.contact || '',
          address: response.customer.address || ''
        };
        this.formGroup.get('lookupCustomer')?.setValue(this.customerDetails.code);
      }
      this.inquiryItemsHeaders = [...Object.keys(this.inquiryItemsColumns || {}), 'save'];
      this.inquiryItemsData = this.flattenData(response?.items);
    }, (error) => {
      console.log("Error while fetching quote details:", error);
    })
  }

  createComment() {
    const payload = {
      code: this.inquiryId,
      ...this.commentForm.value,
    };
    this.pinsService.createComment(payload).subscribe((res: any) => {
      this.fetchComments(payload.code);
      this.commentForm.reset();
    })
  }
  fetchComments(id: any) {
    this.pinsService.getCommentsList(id).subscribe((res) => {
      this.commentsList = res;
    });
  }
  getCommentUser(name: string): string {
    return name
      .split(/[_\s]+/)
      .map(value => value.charAt(0).toUpperCase())
      .join("");
  }
  get userName(): string {
    if (this._userName === null) {
      this._userName = this.getName();
    }
    return this._userName;
  }
  getName() {
    let name = this.authenticationService.getUserName();
    if (!name) {
      return "";
    }
    const userName = name.split(" ").map(value => value.charAt(0).toUpperCase());
    return userName.slice(0, 2).join("");
  }
  showSnackbar(message: string) {
    this.snack.open(message, 'close', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
  deleteInquiry(id: any) {
    this.inquiryService.deleteInquiry(id).subscribe((res) => {
      this.showSnackbar(res);
      this.inquiryUpdated.emit("");
    },
      (error) => {
        this.showSnackbar('Unable to delete quote');
        console.log(error);
      })
  }
  shareInquiry(id: any) {
    this.inquiryService.downloadPdf(id).subscribe((blob: any) => {
      const link = document.createElement('a');
      let url = URL.createObjectURL(blob);
      let fileName = `inquiry_${id}.pdf`;
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.showSnackbar('Downloaded successfully');
    }, (error) => {
      console.error('Error downloading PDF', error);
    });
  }
  clearInputField(fieldName: string) {
    this.formGroup.get(fieldName)?.setValue('');
  }
  createOverlay() {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create({
        hasBackdrop: true,
        backdropClass: 'overlay-backdrop',
        positionStrategy: this.overlay.position().global().right('0px').top('0px'),
        scrollStrategy: this.overlay.scrollStrategies.block(),
        height: '100vh',
        width: '400px'
      });

      const templatePortal = new TemplatePortal(this.overlayTemplate, this.viewContainerRef);
      this.overlayRef.attach(templatePortal);

      this.overlayRef.backdropClick().subscribe(() => {
        this.closeOverlay();
      });
    }
  }
  closeOverlay() {
    this.overlayRef.dispose();
    this.overlayRef = null!;
  }
  openSales360() {
    if (this.customerDetails.id) {
      this.createOverlay();
      this.inquiryService.getCustomerOrder(this.customerDetails.code).subscribe((res: any) => {
        this.customerSalesData = this.flattenData(res);
        this.customerSalesHeaders = Object.keys(this.inquiryOrdersColumns);
      })
    } else {
      this.showSnackbar("Please lookup for customer before proceeding");
    }

  }
  deleteItem(item: any) {
    this.inquiryService.deleteInquiryItem(item.id).subscribe((res: any) => {
      this.showSnackbar(res);
      this.getInquiryDetails(this.inquiryId);
    })
  }

}
