<div class="pivot-table-container">

    <div *ngIf="isLoading">
        <ng-container>
            <div class="holder-card">
                <div style="font-weight: lighter; width: 100%; height: 2em" class="flowsmart-placeholder"></div>
                <div *ngFor="let row of [0,1,2]" class="d-flex justify-content-between">
                    <div style="font-weight: lighter; width: 23%; height: 2em" class="flowsmart-placeholder"></div>
                    <div style="font-weight: lighter; width: 23%; height: 2em" class="flowsmart-placeholder"></div>
                    <div style="font-weight: lighter; width: 23%; height: 2em" class="flowsmart-placeholder"></div>
                    <div style="font-weight: lighter; width: 23%; height: 2em" class="flowsmart-placeholder"></div>
                </div>
            </div>
        </ng-container>
    </div>

    <ejs-pivotview *ngIf="!isLoading" #pivotview [id]="pivotId" allowExcelExport='true' showToolbar='true'
        [toolbar]='toolbarOptions' [dataSourceSettings]="dataSourceSettings" (dataBound)='dataBound($event)'
        enableFooter='true' spinnerTemplate="<i class='fa fa-cog fa-spin fa-3x fa-fw'></i>" width="100%"
        height="400"></ejs-pivotview>
</div>