
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
@Injectable()

export class LandingPageGuard implements CanActivate {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    // canActivate(activatedRoute: ActivatedRouteSnapshot) {
    //     if (this.utilityService.getCookie('isLoggedIn')) {
    //         return true;
    //     }
    //     this.router.navigate(['/dashboard']);
    // }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log(this.authenticationService.getRole());
        if (this.authenticationService.getRole() == "Admin") {
            this.router.navigateByUrl('/ask-hawk');
        } else if (this.authenticationService.getRole() == "Supplier") {
            this.router.navigateByUrl('/grid?reportName=Supplier_Dispatch_Report', { skipLocationChange: true });
        } else if (this.authenticationService.getRole() == "WarehouseManager") {
            this.router.navigateByUrl('/ask-hawk');
            // this.router.navigateByUrl('/replenishment-plan', { skipLocationChange: true });
        } else {
            this.router.navigateByUrl('/sales-analyser', { skipLocationChange: true });
        }

        return true;
    }
}
