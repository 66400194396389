import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { ChartType, Chart } from "chart.js/auto";
import zoomPlugin from 'chartjs-plugin-zoom';
import { DatePipe } from "@angular/common";
import { htmlLegendPlugin } from "./html-legend-plugin";
import { AppSettingService } from "src/app/services/app-setting.service";
@Component({
  selector: "app-dashboard-chatjs-molecule",
  templateUrl: "./dashboard-chatjs-molecule.component.html",
  styleUrls: ["./dashboard-chatjs-molecule.component.scss"],
})
export class DashboardChatjsMoleculeComponent implements OnChanges, OnDestroy {

  @ViewChild("myChart", { static: false }) barChartElement: ElementRef;
  ctx: any;
  myChart: Chart;
  legendContainerID: string;
  @Input() data;
  @Input() htmlLegend: boolean;
  @Input() dynamic_headers: any;
  @Input() chartType: ChartType;
  @Input() chartData: any;
  @Input() header: any;
  @Input() size: any;
  @Input() enableZoom: boolean = false;
  @Input() zoomInstruction: string = null;
  reportName: string;
  isZoomed: boolean = false;
  @Input() count: any;
  @Input() defaultChartLabels:any;
  settings: any

  constructor(private settingService: AppSettingService) { }

  ngAfterViewInit() {
    Chart.register(zoomPlugin);
    this.settings = this.settingService.getSettings();
    this.loadChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartData) {
      this.loadChart();
    }
  }

  loadChart() {
    if (this.barChartElement && this.barChartElement.nativeElement) {
      const ctx = this.barChartElement.nativeElement.getContext("2d");
      this.legendContainerID = "legend-container-" + Math.random();
      let options = this.chartData.options ? this.chartData.options : {};
      if (options.plugins?.zoom?.zoom) {
        options.plugins.zoom.zoom.onZoom = (event) => {
          this.isZoomed = true;
        }
      } else if (this.enableZoom) {
        this.zoomInstruction = "Zoom in on the chart by holding Shift and selecting an area."
        options.plugins = options.plugins ? options.plugins : {};
        options.plugins.zoom = {
          zoom: {
            wheel: {
              enabled: false,
              speed: 0.5,
            },
            drag: {
              enabled: true,
              modifierKey: "shift"
            },
            pinch: {
              enabled: true,
            },
            mode: 'x',
            onZoom: (event) => {
              this.isZoomed = true;
            }
          },
          pan: {
            enabled: true,
            mode: 'x',
          }

        }
      }
      if (this.htmlLegend) {
        options.plugins.legend = {
          display: false,
        };
        this.chartData.options = options;
        this.chartData.plugins = this.chartData.plugins
          ? [htmlLegendPlugin, ...this.chartData.plugins]
          : [htmlLegendPlugin];
      }
      if (this.myChart) {
        this.myChart.destroy(); // Destroy the existing chart instance
      }
      if (this.chartData && this.chartData.data && this.chartData.type == "scatter") {
        const rateOfSaleLabels = this.settings?.rateOfSaleLabels || {};
        if (this.defaultChartLabels?.length > 0 || Object.keys(rateOfSaleLabels)?.length > 0) {
          const visibleLabels = new Set(this.defaultChartLabels);
          this.chartData.data.datasets.forEach((dataset: any) => {
            const originalLabel = dataset.label;
            dataset.label = rateOfSaleLabels[originalLabel] || originalLabel;
            dataset.hidden = !visibleLabels.has(originalLabel);
          });
        }
      }
      this.myChart = new Chart(ctx, this.chartData);
    }
  }

  ngOnDestroy(): void {
    if (this.myChart) {
      this.myChart.destroy(); // Destroy the chart instance when the component is destroyed
    }
  }

  resetZoom() {
    this.myChart.resetZoom();
    this.isZoomed = false;
  }
}
