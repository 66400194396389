import { Component, OnInit } from "@angular/core";
import { FilterUiService } from "src/app/services/filter-ui.service";
import { ForecastingService } from "src/app/services/forecasting.service";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-forecasting-gynoveda-organism',
  templateUrl: './forecasting-gynoveda-organism.component.html',
  styleUrls: ['./forecasting-gynoveda-organism.component.scss'],
  providers: [DatePipe]
})
export class ForecastingGynovedaOrganismComponent implements OnInit {
  listScenarios;
  formData;
  updateData: boolean = false;
  categoryL1: any;
  activeIndex: any = 0;
  renderScenario = true;
  fieldData: any;
  headerData: any;
  visiblePOPUP: boolean;
  constructor(private forecasting: ForecastingService, private filterService: FilterUiService, private datePipe: DatePipe) { }
  ngOnInit(): void {
    /*this.filterService.getFilters(['FG', 'TRADED']).subscribe(res => {
      this.categoryL1 = res['filterDataList'].find(i => i.code === 'categoryL1').values;
    })*/
    this.forecasting.getListScenarios().subscribe((res:Array<any>) => {
      this.listScenarios = res.reverse().filter(scenario => scenario.status == null || scenario.status != 'Deleted');
      const activeScenario: any = this.listScenarios.find(scenario => scenario.active === true);
      if (activeScenario) {
        this.listScenarios = [
          activeScenario,
          ...this.listScenarios.filter((scenario: any) => scenario !== activeScenario),
        ];
      }
      // this.formData = activeScenario || null;
      this.activeIndex = activeScenario ? this.listScenarios.indexOf(activeScenario) : 0;
      this.formData = this.listScenarios.length > 0 ? this.listScenarios[this.activeIndex] : null;
      this.reloadScenario();
    });
  }

  createNewScenario():void {
    this.formData = null;
    this.reloadScenario()
  }
  onScenarioUpdated(code): void {
    this.forecasting.getListScenarios().subscribe((res:Array<any>) => {
      this.listScenarios = res.reverse().filter(scenario => scenario.status == null || scenario.status != 'Deleted');
      const activeScenario: any = this.listScenarios.find(scenario => scenario.code === code);
      this.activeIndex = activeScenario ? this.listScenarios.indexOf(activeScenario) : 0;
    });
  }

  handleItemClick(item, index) {
    this.activeIndex = index;
    this.formData = item;
    // delete this.formData;
    this.reloadScenario()
    // setTimeout(() => {
    //   this.formData = item;
    // }, 0)
  }

  reloadScenario() {
    this.renderScenario = false;
    setTimeout(() => this.renderScenario = true, 0);
  }

  getMessage(item: any) {
    return `Demand conversion has happened on ${this.datePipe.transform(item.demandConversionDate, 'MMMM d, yyyy')}`;
  }

  handlePOPUP(event) {
    this.fieldData = event.fieldData;
    this.headerData = event.header;
    this.visiblePOPUP = true;
  }
}
