<mat-accordion>
  <mat-expansion-panel (opened)='triggerOpenEvent("Rate of Sale")'>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Rate of Sale and Discount offered</strong>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-pu-ros-molecule *ngIf="data" [data]="data" [code]="code" [defaultChartLabels]="defaultChartLabels"></app-pu-ros-molecule>

  </mat-expansion-panel>
  <mat-expansion-panel (opened)='triggerOpenEvent("Stock Trend")'>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Stock and Eco Trend</strong>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-pu-stock-trend-molecule [code]="code"></app-pu-stock-trend-molecule>
  </mat-expansion-panel>
  <mat-expansion-panel (opened)='triggerOpenEvent("Product Availability")'>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Product Availablity</strong>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-pu-bpr-molecule [code]="code"></app-pu-bpr-molecule>
  </mat-expansion-panel>
  <mat-expansion-panel (opened)='triggerOpenEvent("Dynamic Norm Review")'>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Review Dynamic Norm</strong>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <!-- <mat-toolbar class="header display-flex justify-content-start mat-elevation-z4">
      <span>{{displayName}}</span>
    </mat-toolbar> -->
    <app-syncfusion-grid *ngIf="dynamicHeaders" [dynamic_headers]="dynamicHeaders" [data]="gridData"
      (dataEmitForDynamicNormReview)="actionEvent($event)" [dynamicNormForm]="dynamicNormForm"></app-syncfusion-grid>
  </mat-expansion-panel>
</mat-accordion>
