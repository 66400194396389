import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  HostListener,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DataResult, DataStateChangeEventArgs } from '@syncfusion/ej2-angular-grids';
import { Subscription } from "rxjs";
import { subHeadings } from "src/app/properties/map_sub_heading";
import { SyncfusionService } from "src/app/services/syncfusion.service";
import { AppSettingService } from 'src/app/services/app-setting.service';



@Component({
  selector: "app-sync-fusion-organisms",
  templateUrl: "./sync-fusion-organisms.component.html",
  styleUrls: ["./sync-fusion-organisms.component.scss"],
})
export class SyncFusionOrganismsComponent implements OnDestroy {
  reportName: any;
  reportNameWithSpace: any;
  data: any;
  dynamic_headers: [];
  today = new Date();
  count: any;
  tableDataFetched: boolean = false;
  subHeadings = subHeadings;

  private queryParamsSubscription: Subscription;
  visiblePOPUP: boolean;
  fieldData: any;
  headerData: any;
  constructor(
    private route: ActivatedRoute,
    private syncfusion: SyncfusionService,
    private cdr: ChangeDetectorRef,
    private settingsService: AppSettingService
  ) {
    this.queryParamsSubscription = this.route.queryParams.subscribe(
      (params) => {
        this.data = [];
        this.dynamic_headers = [];
        if (params && params.reportName) {
          this.reportName = params.reportName;
          this.reportNameWithSpace = "Just a moment, we're fetching your report...";
          this.tableDataFetched = false;
          if (this.settingsService.isRemoteGridMode()) {
            this.syncfusion.getPaginatedSyncfusionData(this.reportName).subscribe((res) =>{

              if (res) {
                if (res.displayName && res.displayName.trim() !== '') {
                  this.reportNameWithSpace = res.displayName;
                }
                else {
                  this.reportNameWithSpace = params.reportName.replace(/_/g, " ");
                }
                this.data = (<DataResult>{
                  result: res.items,
                  count: res.pageData ? res.pageData.totalRecords : res.count
                });
                this.dynamic_headers = JSON.parse(res.headerContent);
                this.syncfusion.setExportData(res);
                this.count = res.count;
                setTimeout(() => {
                  this.tableDataFetched = true;
                });
              }
            },(error)=>{
              this.tableDataFetched = true;
              console.error(error);
            })
          } else {
            this.syncfusion.getSyncfusionData(this.reportName).then((res) => {
              if (res) {
                this.data = res.items;
                this.dynamic_headers = JSON.parse(res.headerContent);
                this.count = res.count;
                setTimeout(() => {
                  this.tableDataFetched = true;
                });
              }
            },(error) =>{
              this.tableDataFetched = true;
              console.error(error);
            });
          }
        }
      }
    );
  }


  navigateGrid(state: DataStateChangeEventArgs) {
    this.syncfusion.getPaginatedSyncfusionData(this.reportName,this.syncfusion.convertGridStateToPageData(state)).subscribe((res) =>{
      if (res) {
        this.data = (<DataResult>{
          result: res.items,
          count: res.pageData ? res.pageData.totalRecords : res.count
        });
        this.syncfusion.setExportData(res);
        setTimeout(() => {
          this.tableDataFetched = true;
        });
      }
    },(error)=>{
      this.tableDataFetched = true;
      console.error(error);
    })
  }

  handlePOPUP(event) {
    this.fieldData = event.fieldData;
    this.headerData = event.header;
    this.visiblePOPUP = true;
  }
  ngOnDestroy(): void {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe(); // Unsubscribe to avoid memory leaks
    }
  }
}
