<div class="inquiries-container">
    <div class="left-column">
        <mat-toolbar class="d-flex justify-content-around header header-background">
            <span class="headline">Quotes</span>
        </mat-toolbar>
        <ul>
            <li class="inquiries-list d-flex justify-content-between " [class.active]="i === activeIndex"
                (click)="handleItemClick(item, i)"
                [ngStyle]="{'color': item.active && i === activeIndex ? '#ffffff' : (item.active ? '#ffa000' : ''),'font-weight': i === activeIndex ? 'bold' : 'normal'}"
                *ngFor="let item of inquiries; let i = index">
                {{item.code}}
                <mat-icon *ngIf="item.status" [ngClass]="{ 'green-tick': item.status === 'approved','red-cross': item.status === 'rejected',
                'red-stop': item.status === 'pending'}">
                    {{
                    item.status === 'approved' ? 'check_circle': item.status === 'rejected' ? 'cancel': 'block'
                    }}
                </mat-icon>
            </li>
        </ul>
        <div class="actions">
            <button class="mat-button mat-primary mat-raised-button" type="submit" (click)="createNewInquiry()">
                <mat-icon matTooltip="Create">note_add</mat-icon> Create
            </button>
        </div>
    </div>
    <div class="right-column">
        <app-inquiries-molecule *ngIf="renderInquiry" (refreshInquiryList)="refreshInquiriesList($event)" (inquiryUpdated)="getInquiries()" [formData]="formData"></app-inquiries-molecule>
    </div>
</div>