import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import {api} from '../properties/endpoints';


@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(private http: HttpClient) { }

  getAllStock() {
    //const api = '../../assets/mock/getAllStock.json';
    const url = `${api.host}/stockcriteria/all`;
    return this.http.get(url);
  }
  getNextStockCriteria(url){
    return this.http.get(url);
  }
  getStockForm() {
    // const api = '../../assets/mock/getStockMetaData.json';
    const url = `${api.host}/stockcriteria/metadata`;
    return this.http.get(url);
  }

  getStocksByMaterialAndLocationCodes(materialCode:string, locationCode:string){
    //const api = '../../assets/mock/getStocksByMaterialAndLocationCodes.json';
     const url = `${api.host}/stockcriteria/materialcode/${materialCode}/locationcode/${locationCode}`;
    return this.http.get(url);
  }

  getStockAttributesByMaterialAndLocation(materialCode : string, locationCode:string){
    //const api = '../../assets/mock/getStockAttributesByMaterialAndLocation.json';
     const url = `${api.host}/stockcriteria/attributes/materialcode/${materialCode}/locationcode/${locationCode}`;
    return this.http.get(url);
  }

//   saveStock(formValues, formData, addFlag, id): Observable<any[]>{
//     const locationSaveApi = `${api.host}/stockcriteria/save`;
//     const locationAttributeSaveApi = `${api.host}/stockcriteria/update/attributes`;
//     let resultJson = {};
//     let resultJsonAttributes = [];
//     resultJson['id'] = id;
//     formData.map((eachFormData) => {
//       if(eachFormData.dynamic) {
//         const attributeId = addFlag ? null : eachFormData.id;
//         resultJsonAttributes.push({
//           'id': attributeId,
//           'materialCode': formValues['materialCode'].value,
//           'locationCode': formValues['locationCode'].value,
//           'attributeName': eachFormData.identifier,
//           'attributeValue': formValues[eachFormData.identifier].value
//         });
//       } else {
//         resultJson[eachFormData.identifier] = formValues[eachFormData.identifier].value; 
//         if(eachFormData.dataType == "Array") {
//           resultJson[eachFormData.identifier] = formValues[eachFormData.identifier].value && formValues[eachFormData.identifier].value instanceof Array ? formValues[eachFormData.identifier].value : formValues[eachFormData.identifier].value.split(','); 
//         }
//       }
//     });
//     return forkJoin([this.http.post<any>(locationSaveApi, resultJson), this.http.post<any>(locationAttributeSaveApi,resultJsonAttributes)]);
// }

saveStock(formValues, formData, addFlag, id): Observable<any[]> {
  const locationSaveApi = `${api.host}/stockcriteria/save`;
  const locationAttributeSaveApi = `${api.host}/stockcriteria/update/attributes`;
  let resultJson = { id };
  let resultJsonAttributes = [];

  formData.forEach((eachFormData) => {
    if (eachFormData.dynamic) {
      // Include dynamic attributes only if they have values
      const attributeValue = formValues[eachFormData.identifier]?.value || '';
      if (attributeValue) {
        const attributeId = addFlag ? null : eachFormData.id;
        resultJsonAttributes.push({
          id: attributeId,
          materialCode: formValues['materialCode']?.value || '',
          locationCode: formValues['locationCode']?.value || '',
          attributeName: eachFormData.identifier,
          attributeValue,
        });
      }
    } else {
      // Include non-dynamic fields only if they have values
      const value = formValues[eachFormData.identifier]?.value || '';
      if (value) {
        if (eachFormData.dataType === "Array") {
          resultJson[eachFormData.identifier] = Array.isArray(value) ? value : value.split(',');
        } else {
          resultJson[eachFormData.identifier] = value;
        }
      }
    }
  });

  console.log('Result JSON:', resultJson);
  console.log('Result JSON Attributes:', resultJsonAttributes);

  // Return forkJoin for parallel API calls
  return forkJoin([
    this.http.post<any>(locationSaveApi, resultJson),
    this.http.post<any>(locationAttributeSaveApi, resultJsonAttributes),
  ]);
}


}
