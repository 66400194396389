import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from '../properties/endpoints';

@Injectable({
  providedIn: 'root'
})
export class SizeRatioConverterService {

  constructor(private http: HttpClient) { }

  getProductionRateData(payload){
    const url = `${api.host}/material/compute/production-rate`;
    return this.http.post(url,payload);
  }
  getSuggestedAttributes(key:any) {
    const url = `${api.host}/material/lookup/attribute-values?query=${key}&attribute=parentProductCode `;
    return this.http.get(url);
  }
}
