import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from '../properties/endpoints';

@Injectable({
  providedIn: 'root'
})
export class InquiriesManagementService {

  constructor(private http: HttpClient) { }

  createInquiry(payload:any){
    const url = `${api.host}/material/inquiries/create`;
    return this.http.put(url,payload);
  }
  getInquiriesList(){
    const url=`${api.host}/material/inquiries/list`
    return this.http.get(url);
  }
  getInquiryDetails(id:any){
    const url=`${api.host}/material/inquiries/details?inquiryId=${id}`
    return this.http.get(url);
  }
  getMaterialLookup(code:any){
    const url=`${api.host}/material/inquiries/material/lookup?code=${code}`
    return this.http.get(url);
  }
  getCustomerLookup(code:any){
    const url=`${api.host}/material/inquiries/customer/lookup?code=${code}`
    return this.http.get(url);
  }
  getCustomerOrder(custId:any){
    const url=`${api.host}/material/inquiries/orders/list?customerId=${custId}`
    return this.http.get(url);
  }
  downloadPdf(id:any){
    const url=`${api.host}/material/inquiries/download/${id}`
    return this.http.get(url, { responseType: 'blob' });
  }
  deleteInquiry(id: any){
    const url = `${api.host}/material/inquiries/delete/${id}`;
    return this.http.delete(url,{responseType: 'text'});
  }
  selectCustomerForInquiry(inquiryId:any,customerId:any){
    const url = `${api.host}/material/inquiries/select-customer/${inquiryId}/?customerId=${customerId}`;
    return this.http.post(url,{});
  }
 addItemstoInquiry(payload:any,id:any){
    const url = `${api.host}/material/inquiries/add-items/${id}`;
    return this.http.put(url,payload);
  }
  updateInquiryItem(payload:any){
    const url = `${api.host}/material/inquiries/items/update`;
    return this.http.post(url,payload);
  }
  deleteInquiryItem(id:any){
    const url = `${api.host}/material/inquiries/delete/inquiry_item/${id}`;
    return this.http.delete(url,{responseType: 'text'});
  }
}
