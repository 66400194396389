import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FormBase } from '../../../interfaces/formInterface';

@Component({
  selector: 'app-dynamic-form-field-molecule',
  templateUrl: './dynamic-form-field-molecule.component.html',
  styleUrls: ['./dynamic-form-field-molecule.component.scss']
})
export class DynamicFormFieldMoleculeComponent implements OnInit, OnChanges {
  @Input() fieldData: FormBase<any>;
  @Input() form: UntypedFormGroup;
  currentDate = new Date();
  constructor(private fb: UntypedFormBuilder) { }

  ngOnChanges() {
  }
  removeObjects(index) {
    this.returnArray().removeAt(index);
  }
  returnArray(): UntypedFormArray {
    return this.form.get('components') as UntypedFormArray;
  }
  createFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      code: new UntypedFormControl('', Validators.required),
      qty: new UntypedFormControl('', [Validators.required, Validators.min(1)])
    });
  }
  addFormGroup() {
    (<UntypedFormArray>this.form.get('components')).push(this.createFormGroup());
  }

  onDateChange(event: any, fieldData: any){
    const date = new Date(event.value);
    if(fieldData.dynamic)
    {
      const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
      const selectedDate = adjustedDate.toISOString().split('T')[0];
      this.form.get(fieldData.identifier)?.setValue(selectedDate);
    }
  }

  ngOnInit() {
  }
  get isValid() {
    return this.form.controls[this.fieldData.identifier].valid;
  }

}
