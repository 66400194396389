<div class="pins-container">
    <ng-container *ngIf="isLoading; else pinContent">
        <div class="combined-icon">
            <mat-icon class="mode-icon">mode_comment</mat-icon>
            <mat-icon class="sync-icon">sync</mat-icon>
        </div>
    </ng-container>
    <ng-template #pinContent>
        <ng-container *ngIf="getCount(data) > 0; else addPin">
            <div class="combined-icon">
                <mat-icon class="mode-icon">mode_comment</mat-icon>
                <span class="number-overlay" (click)="pinsOverview(data)">{{ getCount(this.data) }}</span>
            </div>
        </ng-container>
        <ng-template #addPin>
            <mat-icon class="add-pin-icon" matTooltip="Create Pin" (click)="createPin()">add_comment</mat-icon>
        </ng-template>
    </ng-template>
</div>

<ng-template #overlayTemplate>
    <div class="overlay-panel" [@childAnimation] *ngIf="!showList" [formGroup]="form">
        <div class="overlay-header">
            <div *ngIf="!editing">Create Pin</div>
            <div *ngIf="editing"><mat-icon style="color: #ffa000;">chat</mat-icon> Pins</div>
            <mat-icon class="close-icon" (click)="closeOverlay()">close</mat-icon>
        </div>
        <div class="input-field-cont">
            <div>
                <div>{{ header?.headerText }}:</div>
                <div style="background-color: #d5d5d5;"><input type="text" formControlName="code" /></div>
            </div>
            <mat-icon matTooltip="{{isPrivate ? 'Make it public' : 'Make it private'}}" (click)="togglePrivacy()"
                *ngIf="!editing">{{ isPrivate ? 'lock' : 'lock_open' }}</mat-icon>
            <div class="actions-cont" *ngIf="editing">
                <mat-icon matTooltip="Delete" (click)="deleteItem(dialogTemplate,editItemId)">delete</mat-icon>
                <mat-icon matTooltip="{{isPrivate ? 'Make it public' : 'Make it private'}}"
                    (click)="togglePrivacy(editItemId)">{{ isPrivate ? 'lock' : 'lock_open' }}</mat-icon>
            </div>
        </div>
        <div class="created-fields-cont" *ngIf="editing">
            <mat-hint align="start" class="hint-field">
                <mat-icon class="info-icon">info</mat-icon>
                <span class="info-text">Created by <strong>{{this.createdBy}}</strong> on
                    <strong>{{this.createdOn}}</strong></span>
            </mat-hint>
        </div>
        <textarea matInput placeholder="Add Description" rows="5" formControlName="description"
            value="description"></textarea>
        <button mat-button class="save-btn" (click)="!editing ? saveForm() : updatePin(editItemId)"
            [disabled]="form.invalid" *ngIf="!editing || (editing && form.get('description')?.dirty)">Save</button>
        <div class="comment-section" *ngIf="editing" [formGroup]="commentForm">
            <div>
                <div>
                    <button mat-button class="no-style-button">
                        <span class="account_icon">{{userName}}</span>
                    </button>
                    <div>
                        <textarea matInput placeholder="Add Comment" rows="2" formControlName="comment"
                            style="margin: 7px;" value="comment"></textarea>
                    </div>
                </div>

                <button mat-button class="save-btn" (click)="createComment()" [disabled]="form.invalid"
                    *ngIf="(editing && commentForm.get('comment')?.dirty)">Save</button>
            </div>

            <div class="comments-list-cont">
                <div class="list-item" *ngFor="let comment of commentsList">
                    <button mat-button class="no-style-button">
                        <span class="account_icon">{{getCommentUser(comment.createdBy)}}</span>
                    </button>
                    <div class="text-cont">
                        <div class="text-header">
                            <span>
                                <strong class="user-name">{{ getCommentUser(comment.createdBy) }}</strong>
                                <span>&nbsp;</span>
                                <span>added a <strong>Comment</strong> <span>&nbsp;</span> {{ comment.recordDate |
                                    date:'MMMM dd, yyyy '}} at {{ comment.recordDate | date:'h:mm a'}}</span></span>
                        </div>
                        <div class="text-content">{{comment.comment}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="overlay-panel" [@childAnimation] *ngIf="showList">
        <div class="overlay-header">
            <div>Pins</div>
            <mat-icon class="close-icon" (click)="closeOverlay()">close</mat-icon>
        </div>
        <div class="overlay-content">
            <div class="list-item" *ngFor="let item of items; let i = index" (click)="editPin(item)">
                <div class="text-cont">
                    <div class="header-text">{{ header.headerText}} - {{item.code}}</div>
                    <div class="truncated-description" matTooltip="{{ item.description }}">{{ item.description }}</div>
                </div>
                <div class="actions-cont">
                    <mat-icon matTooltip="Delete"
                        (click)="deleteItem(dialogTemplate,item.id);$event.stopPropagation()">delete</mat-icon>
                    <mat-icon matTooltip="{{item.keepPrivate ? 'Make it public' : 'Make it private'}}"
                        (click)="togglePrivacy(item.id);$event.stopPropagation()">
                        {{ item.keepPrivate ? 'lock' : 'lock_open' }}</mat-icon>
                </div>
            </div>
        </div>
        <div class="btn-container">
            <button mat-button class="create-btn" (click)="createPin()"><mat-icon class="create-btn-icon"
                    matRippleDisabled="true">
                    chat</mat-icon>Create</button>
        </div>
    </div>
</ng-template>
<ng-template #dialogTemplate let-dialogRef="dialogRef">
    <h2 mat-dialog-title>Confirm Action</h2>
    <mat-dialog-content>
        <span>Are you sure you want to delete this pin?</span>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button (click)="dialogRef.close()">Cancel</button>
        <button mat-button color="warn" (click)="dialogRef.close('delete')">Delete</button>
    </mat-dialog-actions>
</ng-template>