<div class="download-container" #downloadContainer [ngStyle]="{'background-color': showList ? 'black' : 'transparent'}">
  <button cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="toggleList()" class="download-button">
    <mat-icon style="color: #ffa100;">save_alt</mat-icon>
  </button>
  <ng-template cdkConnectedOverlay [cdkConnectedOverlayPositions]="overlayPositions"
    [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="showList">
    <div class="download-list mat-elevation-z4">
      <div *ngFor="let item of downloadedItems" class="download-item">
        <div class="item-header">
          <mat-icon [ngClass]="{'processing': item.status === 'PROCESSING','queued': item.status === 'QUEUED',
            'completed': item.status === 'COMPLETED','failed': item.status === 'FAILED'}">
            {{
            item.status === 'PROCESSING' ? 'hourglass_top' :
            item.status === 'QUEUED' ? 'pause_circle' :
            item.status === 'COMPLETED' ? 'check_circle' :
            'error'
            }}
          </mat-icon>
          <div class="content-cont">
            <div class="content">
              <span>{{item.reportName}}</span>
              <span class="item-time">{{ item.recordDate | date:'short' }}</span>
            </div>
            <div *ngIf="item.status === 'COMPLETED'">
              <button mat-raised-button style="font-size: 12px;" color="primary" (click)="downloadItem(item)"
                class="custom-button">
                Download
              </button>
              <span>{{item.recordCount}} records</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>