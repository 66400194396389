<div class="fs-padding">
    <div class="fs-section-block fs-margin-0 header-background ">
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <span class="mat-headline">Inventory Snapshot</span>
                <p class="fs-hint-text mt-1">Track inventory by brand and location</p>
            </div>
        </div>
    </div>
    <div class="fs-padding-0-1 mt-2">
        <span style="font-size: 20px;">Stock for Apparels</span>
        <app-pivot-table-molecule [pivotId]="'pivot1'" [rowCollection]="rowCollection" [rowField]="rowField"
            [columnCollection]="columnCollection" [columnField]="columnField"
            [rowFilters]="apparelRowFilters"></app-pivot-table-molecule>

    </div>
    <div>
        <mat-toolbar class="mat-toolbar justify-content-between mat-toolbar-single-row">
            <span>Stock for Accessories</span>
        </mat-toolbar>
        <div class="fs-padding-0-1">
            <app-pivot-table-molecule [pivotId]="'pivot2'" [rowCollection]="rowCollection" [rowField]="rowField"
                [columnCollection]="columnCollection" [columnField]="columnField"
                [rowFilters]="accessoryRowFilters"></app-pivot-table-molecule>
        </div>
    </div>
</div>