import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InquiriesManagementService } from 'src/app/services/inquiries-management.service';

@Component({
  selector: 'app-inquiries-management-organism',
  templateUrl: './inquiries-management-organism.component.html',
  styleUrls: ['./inquiries-management-organism.component.scss']
})
export class InquiriesManagementOrganismComponent {
  inquiries: any = [];
  activeIndex: any = 0;
  formData!: FormGroup;
  renderInquiry: boolean = true;

  constructor(private inquiryService: InquiriesManagementService) { }

  ngOnInit(): void {
    this.getInquiries();
  }
  getInquiries() {
    this.inquiryService.getInquiriesList().subscribe((res: Array<any>) => {
      this.inquiries = res;
      const activeScenario: any = this.inquiries.find((scenario: any) => scenario.active === true);
      this.formData = activeScenario || null;
      this.activeIndex = activeScenario ? this.inquiries.indexOf(activeScenario) : 0;
      this.reloadInquiry();
    });
  }
  createNewInquiry(): void {
    this.formData = null;
    this.reloadInquiry()
  }
  refreshInquiriesList(newInquiry: any) {
    this.inquiryService.getInquiriesList().subscribe((res: Array<any>) => {
      this.inquiries = res;
      setTimeout(() => {
        this.activeIndex = this.inquiries.findIndex((inquiry: any) => inquiry.id === newInquiry.id);
        this.handleItemClick(newInquiry, this.activeIndex);
      }, 0);
    });
  }

  handleItemClick(item: any, index: any) {
    this.activeIndex = index;
    this.formData = item;
    this.reloadInquiry()
  }
  reloadInquiry() {
    this.renderInquiry = false;
    setTimeout(() => this.renderInquiry = true, 0);
  }
}
