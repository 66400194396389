import { Component, Input, OnInit } from "@angular/core";
import { ListingGridService } from "../../../services/listing-grid.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MaterialsService } from "src/app/services/materials.service";
import { AppSettingService } from "src/app/services/app-setting.service";

@Component({
  selector: "app-product-card-molecule",
  templateUrl: "./product-card-molecule.component.html",
  styleUrls: ["./product-card-molecule.component.scss"],
})
export class ProductCardMoleculeComponent implements OnInit {
  @Input() item: any;
  @Input() detailedView: boolean = false;
  @Input() disableRoute: boolean = false;
  @Input() showAdditionalInfo: boolean = false;
  routeList;

  rosTrendIcon: String;
  rosTrendIconColor: String;
  latestDateSold: String;
  rosDifference: Number;
  broken: boolean;
  price: String;
  discount: String;
  blockStatus: { productionBlock: boolean, planningBlock: boolean }
  blockAttributes: string[] = ['productionBlock', 'planningBlock'];
  waitForBlockStatus: boolean;
  lifecycleStatus;
  lifeCycleOptions: string[] = [];
  showAnalytics: boolean = false;

  constructor(
    private listinggridservice: ListingGridService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private matCardModule: MatCardModule,
    private matDividerModule: MatDividerModule,
    private snack: MatSnackBar,
    private lifeCycleStatus: MaterialsService,
    private settingService: AppSettingService
  ) { }

  ngOnInit(): void {
    const forecastSetting = this.getForecastSetting();
    this.showAnalytics = forecastSetting ? true : false;
    this.routeList = this.disableRoute ? null : ['/product/listing', { code: this.item.code }];
    if (this.detailedView) {
      this.listinggridservice.getBlockByProductCode(this.item.code).subscribe(res => {
        this.blockStatus = { planningBlock: res['planningBlock'], productionBlock: res['productionBlock'] }
        if (res['lifeCycleStatus'] === "" || null == res['lifeCycleStatus']) {
          this.lifecycleStatus = "none";
        }
        else {
          this.lifecycleStatus = res['lifeCycleStatus'];
        }
      }, (error) => { }, () => {
        this.waitForBlockStatus = true;
      });

      this.lifeCycleStatus.getMaterialForm().subscribe(res => {
        const lifeCycleStatusObj = res['metaData'].filter(item => item.identifier === 'lifeCycleStatus');
        this.lifeCycleOptions = lifeCycleStatusObj[0].values;
      })
    }
    if (
      this.item.max_sell_price &&
      this.item.min_sell_price &&
      this.item.max_sell_price != this.item.min_sell_price
    ) {
      this.price = `₹${this.item.min_sell_price} - ₹${this.item.max_sell_price}`;
    } else if (this.item.max_sell_price || this.item.min_sell_price) {
      this.price = `₹${this.item.min_sell_price | this.item.max_sell_price}`;
    } else {
      this.price = "-";
    }
    if (
      this.item.max_discount_percent &&
      this.item.min_discount_percent &&
      this.item.max_discount_percent != this.item.min_discount_percent
    ) {
      this.discount = `${this.item.min_discount_percent} - ${this.item.max_discount_percent}%`;
    } else if (
      this.item.max_discount_percent ||
      this.item.min_discount_percent
    ) {
      this.discount = `${this.item.min_discount_percent | this.item.max_discount_percent
        }%`;
    } else {
      this.discount = "-";
    }
    if (null != this.item.latestDateSold) {
      const differenceInDays = Math.floor(
        (new Date().getTime() - new Date(this.item.latestDateSold).getTime()) /
        (1000 * 60 * 60 * 24)
      );
      if (differenceInDays === 1) {
        this.latestDateSold = "Sold yesterday";
      } else {
        this.latestDateSold = `Sold ${differenceInDays} days ago`;
      }
    }
    if (this.item.brokenChannelCount && this.item.brokenChannelCount > 0) {
    }
    if (
      null != this.item.shortTermRateOfSale &&
      null != this.item.mediumTermRateOfSale
    ) {
      const differenceInRos =
        this.item.shortTermRateOfSale - this.item.mediumTermRateOfSale;
      this.rosDifference = Math.floor(
        (differenceInRos / this.item.mediumTermRateOfSale) * 100
      );
      if (differenceInRos <= 1 && differenceInRos >= -1) {
        this.rosTrendIcon = "trending_flat";
        this.rosTrendIconColor = "orange";
      } else if (differenceInRos > 1) {
        this.rosTrendIcon = "trending_up";
        this.rosTrendIconColor = "green";
      } else {
        this.rosTrendIcon = "trending_down";
        this.rosTrendIconColor = "darkred";
      }
    }
  }
  getForecastSetting(): string {
    const settings = this.settingService?.getSettings();
    return settings?.analytics || false;
  }

  handleLifeCycleChange(event) {
    (this.lifecycleStatus !== 'none') && this.listinggridservice.updateMaterialAttribute(this.item.code, 'lifeCycleStatus', this.lifecycleStatus, true).subscribe(res => {
      const msg = `${res}`;
      this.snack.open(msg, "close", {
        duration: 4000,
        horizontalPosition: "center",
        verticalPosition: "top",
      });
    }, (error => {
      const msg = `${error}`;
      this.snack.open(msg, "close", {
        duration: 4000,
        horizontalPosition: "center",
        verticalPosition: "top",
      });
    }))
  }

  onChangeBlockStatus(attribute) {
    this.listinggridservice.updateMaterialAttribute(this.item.code, attribute, this.blockStatus[attribute], true).subscribe(res => {
      const msg = `${res}`;
      this.snack.open(msg, "close", {
        duration: 4000,
        horizontalPosition: "center",
        verticalPosition: "top",
      });
    }, (error => {
      const msg = `${error}`;
      this.snack.open(msg, "close", {
        duration: 4000,
        horizontalPosition: "center",
        verticalPosition: "top",
      });
    }))
  }
}
