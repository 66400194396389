<div class="filters">
    <ng-container *ngIf="isDateFilter" [formGroup]="dateRangeForm">
      <mat-form-field>
        <mat-label>Enter a date range</mat-label>
        <div style="display: flex; justify-content: space-between'">
          <mat-date-range-input [max]="endDate" [rangePicker]="picker">
            <input formControlName="startDate" matStartDate placeholder="Start date" />
            <input formControlName="endDate" matEndDate placeholder="End date" />
          </mat-date-range-input>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-datepicker-toggle style="width:1.5rem;margin:-10px 0" matIconSuffix
            [for]="picker"></mat-datepicker-toggle>
        </div>
        <!-- <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint> -->
      </mat-form-field>
      <!-- <mat-form-field>
        <mat-label>Frequency</mat-label>
        <mat-select #matSelect formControlName="frequency">
          <mat-option value="DAY">DAY</mat-option>
          <mat-option value="WEEK">WEEK</mat-option>
          <mat-option value="MONTH">MONTH</mat-option>
        </mat-select>
      </mat-form-field> -->
    </ng-container>
    <ng-container *ngIf="isLoading">
      <ng-container *ngFor="let number of [0, 1, 2]">
        <div style="width: 20%">
          <p style="
              font-style: normal;
              text-align: center;
              width: 80%;
              height: 2.5em;
              margin: 0px auto;
            " class="flowsmart-placeholder"></p>
        </div>
      </ng-container>
    </ng-container>
    <mat-form-field [formGroup]="filterData" *ngFor="let filter of filterDataList">
      <mat-label>{{ mapFilterLabels(filter.code) | capitalize }}</mat-label>
      <mat-select #matSelect [formControlName]="filter.code" [multiple]="filter.condition == 'OR' ? true : false"
        panelWidth="auto">
        <mat-icon class="close-button" (click)="matSelect.close()" color="primary">close</mat-icon>
        <mat-option (click)="toggleSelectAll(filter.code)" [value]="0">All</mat-option>
        <mat-option *ngFor="let item of filter.values" value="{{ item }}" (click)="selectSingle(filter.code)">{{ item
          }}</mat-option>
      </mat-select>
    </mat-form-field>
  
    <ng-container *ngIf="monthFieldRequired">
      <app-date-month-range-molecule [removeMonthRange]="removeMonthRange" (DateMonthRange)="monthDateRange($event)"
        [returnsDateRange]="returnsDateRange"></app-date-month-range-molecule>
    </ng-container>
  </div>
  <div>
    <button *ngIf="!isLoading" (click)="applyFilters()" class="mat-button mat-primary mat-raised-button">
      <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="filter_alt"></mat-icon>
      Apply Filters
    </button>
  </div>
  