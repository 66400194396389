import { Component, ElementRef, OnInit, ViewChild, TemplateRef , Output , EventEmitter } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { ListingGridService } from "src/app/services/listing-grid.service";
import { ClickStreamAnalyticsService, EventType } from "src/app/services/clickstream-analytics-service";
import { GridComponent, TextWrapSettingsModel } from '@syncfusion/ej2-angular-grids';
import { ChangeDetectorRef } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups'; 
import { POPUPReuseOrgansimComponent } from "../../organisms/popup-reuse-organsim/popup-reuse-organsim.component";
@Component({
  selector: "app-grid-list-molecule",
  templateUrl: "./grid-list-molecule.component.html",
  styleUrls: ["./grid-list-molecule.component.scss"],
})
export class GridListMoleculeComponent implements OnInit {
  pageSize: number;
  currentPage: number = 0;
  latestPage: any;
  executed: boolean;
  isFetchingData: boolean;
  filters: any;
  gridList: any;
  totalRecordCount: number;
  pagesLoaded: number[] = [];
  addFilters;
  showOverlay;
  detectChanges;
  sortFilterOptions: any;
  loading: boolean = true;
  search_with_text: string;
  sortByOption: string;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('productContainer') productContainer!: ElementRef;
  sortByOnChange: any;
  sortByAsc: boolean = false;
  selectedProductCode: any;
  gridListPayload: {
    pageSize: any,
    pageNumber: any,
    filters: any,
    sortOption: any,
    searchText: any,
    sortAsc: any
  }
  dataSource: any[] = [];
  sku;
  isTableView: boolean = false;
  dialogVisible: boolean = false;
  selectedFieldData: any = null;
  selectedHeaderData: string = '';
  @ViewChild('imageTemplate', { static: true }) imageTemplate!: TemplateRef<any>;
  @Output() dataEmitForPOPUP = new EventEmitter<{}>();
  fieldData: any;
  headerData: any;
  visiblePOPUP: boolean;
  @ViewChild('dialog') dialog: DialogComponent; // Reference to the dialog
  public textWrapSettings: TextWrapSettingsModel = { wrapMode: 'Both' }; 
  selectedCode: string;

  constructor(
    private listinggridservice: ListingGridService,
    private route: ActivatedRoute,
    private router: Router,
    private clickStream: ClickStreamAnalyticsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const savedView = sessionStorage.getItem('viewPreference');
    this.isTableView = savedView === 'table';
    this.listinggridservice.getSortByOptions().subscribe((res) => {
      if (res) {
        this.sortFilterOptions = res["options"];
      }
    });
    this.pageSize = 42;
    this.selectedProductCode = this.listinggridservice.getSelectedProduct();

    this.gridListPayload = {
      pageSize: this.pageSize,
      pageNumber: 1,
      filters: {},
      sortOption: "",
      searchText: "",
      sortAsc: false
    };
    this.loadProducts(); // Call loadProducts during initialization

    this.route.queryParams.subscribe((params) => {
      // Handle query params if needed
    });
  }
  // toggleView(): void {
  //   this.isTableView = !this.isTableView; // Toggle between grid and table views
  //   // this.loadProducts();
  //   // this.loading=false;
  // }
  setView(view: string): void {
    this.isTableView = view === 'table';
    sessionStorage.setItem('viewPreference', view);
  }
  onProductClick(event: any): void {
    const selectedRowData = event.data;
    const skuCode = selectedRowData.code;
    this.router.navigate(['/product/listing', { code: skuCode }]);
  }
  // handlePOPUP(data: any, header: string) {
  //   this.selectedFieldData = data;
  //   this.selectedHeaderData = header;
  //   this.dialogVisible = true;
  //   this.dataEmitForPOPUP.emit({ header, fieldData: data });
  // }
  onQuickView(data: any): void {
    this.selectedCode = data.code;  
    console.log(this.selectedCode , "selected")
    // Set the SKU Code from clicked row
    // if (this.dialog) {
    //   this.dialog.show();
    // } else {
    //   console.error('Dialog is not initialized');
    // }
  } 
  onDialogClose(): void {
    this.selectedCode = null; // Reset selected code if needed
  }

  // Handle close popup
  onOverlayClick() {
    this.dialogVisible = false;
  }
  // imageTemplate(data: any): string {
  //   return `<img src="${data.imageUrl}" alt="${data.name}" width="50" height="50" />`;
  // }
  loadProducts() {
    this.loading = true; // Show loading indicator
    this.listinggridservice.getGridList(this.gridListPayload).subscribe((res) => {
      if (res) {
        this.gridList = res["items"];
        this.dataSource = res["items"];
        this.pageSize = res["pageSize"];
        this.currentPage = res["pageNumber"] - 1;
        this.totalRecordCount = res["totalRecordCount"];
        this.loading = false; // Hide loading indicator after fetching data
        this.cdr.detectChanges();
        if (null != res["searchText"]) {
          this.search_with_text = res["searchText"];
        }
        if (null != res["sortOption"]) {
          this.sortByOption = res["sortOption"];
        }
        if (null != res["sortAsc"]) {
          this.sortByAsc = res["sortAsc"];
        }
      }
    });
  }

  getFilters(event) {
    this.showOverlay = false;
    this.filters = event.filters;
    this.gridListPayload = {
      pageSize: this.pageSize,
      pageNumber: 1,
      filters: event.filters,
      sortOption: this.sortByOnChange,
      searchText: this.search_with_text,
      sortAsc: this.sortByAsc
    };
    this.clickStream.publishGAEvent(EventType.FILTER, {
      section: "Product Listing"
    });
    this.loadProducts(); // Call loadProducts when filters change
  }

  toggleOverlay() {
    this.showOverlay = !this.showOverlay;
  }

  selectedFilters(event) {
    this.addFilters = event;
  }

  clickToNextPage(event) {
    const pageIndex = event.pageIndex + 1;
    this.gridListPayload.pageNumber = pageIndex;
    this.loadProducts(); // Call loadProducts when navigating to the next page
  }

  searchWithText() {
    this.gridListPayload.searchText = this.search_with_text;
    this.clickStream.publishGAEvent(EventType.SEARCH, {
      value: this.search_with_text,
      section: "Product Listing"
    });
    this.loadProducts(); // Call loadProducts when searching
  }

  sortByAscOrDes() {
    this.sortByAsc = !this.sortByAsc;
    this.gridListPayload.sortAsc = this.sortByAsc;
    this.clickStream.publishGAEvent(EventType.SORT, {
      value: this.sortByOnChange,
      section: "Product Listing"
    });
    this.loadProducts(); // Call loadProducts when sorting
  }

  handleValueChange(event) {
    this.sortByOnChange = event;
    this.gridListPayload.sortOption = event;
    this.loadProducts(); // Call loadProducts when sort option changes
  }

  onInputChange(event) {
    if (this.search_with_text?.length === 0) {
      this.gridListPayload.searchText = "";
      this.loadProducts(); // Call loadProducts when search input is cleared
    }
  }

  ngAfterViewInit() {
    if (null != this.selectedProductCode && null != this.productContainer) {
      let count = 0;
      const intervalId = setInterval(() => {
        count += 1;
        if (count === 6) {
          clearInterval(intervalId);
        }
        const selectedProductElement = this.productContainer.nativeElement
          .querySelector(`[data-code="${this.selectedProductCode}"]`);
        if (null != selectedProductElement) {
          selectedProductElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          clearInterval(intervalId);
        }
      }, 1000);
    }
  }
}
