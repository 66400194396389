<div *ngIf="chartData.displayname" class="mat-title fs-secondary-text text-center">{{chartData.displayname}}</div>

<ejs-grid #grid  [dataSource]='chartData.data' [allowPaging]="pagination" [pageSettings]="pageSettings" [showColumnChooser]="true" [allowSelection]="true" [allowFiltering]='true'
  [allowSorting]="true" [frozenColumns]="frozenColumns" [enableHeaderFocus]='true' [height]='height' [width]='width'
  [filterSettings]='filterOption' [enableHover]='false' [toolbar]='toolbar' (toolbarClick)='toolbarClick($event)' allowTextWrap='true'
  (actionBegin)='actionBegin($event)' [enableHeaderFocus]='true' [allowExcelExport]='excelExport' [textWrapSettings]='wrapSettings'>
  <e-columns>
    <ng-container *ngFor="let header of chartData.headers">
      <div *ngIf="header.imageRender;then imageTemplate else default"></div>
      <ng-template #imageTemplate>
        <e-column [field]="header.field" [headerText]="header.headerText" [allowFiltering]="false"
          [width]="header.width">
          <ng-template #template let-data>
            <div *ngIf="data[header.field]" class="info-icon cursor-pointer">
              <div (mouseenter)="data.showImage = true" (mouseleave)="data.showImage = false">
                <img style="float: left" src="{{data[header.field] | imageUrl}}" alt="Image" height="30" width="30" />
                <div class="{{data.showImage ? 'speech-bubble' : 'display-hide'}} left">
                  <img src="{{data[header.field] | imageUrl}}" alt="Image" />
                </div>
              </div>
            </div>
            <div *ngIf="!data[header.field]">
              <mat-icon>hide_image</mat-icon>
            </div>
          </ng-template>
        </e-column>
      </ng-template>
      <ng-template #default>
        <e-column *ngIf="!header.allowFiltering" [allowFiltering]="header.allowFiltering" [field]="header.field"
          [headerText]="header.headerText" [textAlign]="header.textAlign != null ? header.textAlign : 'left'"
          [width]="header.width">
        </e-column>
        <e-column *ngIf="header.allowFiltering" [field]="header.field" [headerText]="header.headerText"
          [allowFiltering]="header.allowFiltering" [filter]="header.filter"
          [textAlign]="header.textAlign != null ? header.textAlign : 'left'" [width]="header.width">
        </e-column></ng-template>

    </ng-container>
  </e-columns>
</ejs-grid>
